import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import useScript from '../useScript';
import '../payment.css'

const NewPayment = () => {
    //Add payment-js
    
    useScript("https://code.jquery.com/jquery-3.6.1.min.js");
    // useScript("https://test-gateway.tillpayments.com/js/integrated/payment.1.3.min.js");
    useScript("https://gateway.tillpayments.com/js/integrated/payment.1.3.min.js");
    
    useEffect(() => {

        Axios.get(`${process.env.REACT_APP_API_URL}/api/amountOwed`, {
            headers: {
                id: localStorage.getItem('cin7Id'),
            } 
        }).then((response)=>{
            setOwed(response.data.balanceOwed)
            console.log(response.data.balanceOwed)
            // console.log(response);
        }).catch((response) => {
            console.log(response);
        })

        setTimeout(() => {
            const script = document.createElement('script');
            // TEST: nIDQlW9r38xQ0OlXOsB7
            // LIVE: i0lQd8qigfzk3NNzLATo
            script.type = 'text/javascript';
            script.text = 
            `
                var payment = new PaymentJs();
                payment.init('Tbpa1LrbxtI64HyyM1ay', 'number_div', 'cvv_div', function(payment) {
                    var style = {
                        'height': '100%',
                        'width': '100%',
                        'padding-left': '10px',
                        'border-radius': '3px',
                        'border': '0.5px solid #B6B6B6',
                        'outline': 'none',
                        '-webkit-border-radius': '4px',
                        '-moz-border-radius': '4px',
                        'border-radius': '4px',
                        'margin': 0,
                        'padding-left': '10px',
                        'padding-right': '10px'
                    };
                    let placeHolderNumber = 'XXXX XXXX XXXX XXXX'
                    let cvvPlaceHolder = 'CVV'
                    
                    $('#number_div').css({"border": '', 'border-radius': ''});
                    $('#cvv_div').css({"border": '', 'border-radius': ''});
                    payment.setNumberStyle(style);
                    payment.setNumberPlaceholder(placeHolderNumber)
                    payment.setCvvPlaceholder(cvvPlaceHolder)
                    payment.setCvvStyle(style);
                    $('#payment_div').css({"pointer-events": '', 'opacity': ''});
                    $('#submit').prop("disabled", false);
                });  
    
                function interceptSubmit(e) {
                    e.preventDefault();
                    $('#submit').prop("disabled", true);
                    console.log('heys');

                    var expiryValue = $('#expiry').val();

                    var [monthValue, yearValue] = expiryValue.split('/');

                    var data = {
                        card_holder: $('#card_holder').val(),
                        month: monthValue,
                        year: yearValue,
                    };
                    
                    payment.tokenize(
                        data, //additional data, MUST include card_holder (or first_name & last_name), month and year
                        function(token, cardData) { //success callback function
                            $('#transaction_token').val(token); //store the transaction token
                            $('#payment_form').submit(function(){
                            });
                            $('#transaction_token').click();
                            $('#submit').prop("disabled", false);
                        }, 
                        function(errors) { //error callback function
                            alert('Error occurred: required field empty');
                            $('#submit').prop("disabled", false);
                            console.log(errors);
                            //render error information here
                        }
                    );
                }
            `
            
            document.body.appendChild(script);
            console.log(document.head);
            return () => {
                document.body.removeChild(script);
            }
        }, 2000)
    },[])

    //Store info
    const [owed, setOwed] = useState(null);
    const [amount, setAmount] = useState(0);
    const [reference, setReference] = useState('')
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1130);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1130);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);


    //Display Product
    return (
        <div className='container-payment-page pt-44 pb-28 flex flex-col justify-center'>
        <br/>
            <div className='payment-title'>
                Make Payment
            </div>
            <div className='payment-amount-due mt-4'>
                <h2>Amount Due: ${owed ? 0.00.toFixed(2) : owed?.toFixed(2)}</h2>
            </div>
            <div className='payment-title-warning'>
                <span className='text-lg font-bold'>*</span>
                We accept Visa and Mastercard with a 1.5% processing fee.
            </div>
            <div className={`${isMobile ? 'payment-page-content-mobile' : 'payment-page-content'} p-10 mt-10`}>
                <form className='flex flex-col justify-center' id="payment_form" action='' method="POST" onSubmit={(e) => {window.interceptSubmit(e); return false;}}>
                    <input 
                        type="hidden" 
                        name="transaction_token" 
                        id="transaction_token"
                        onClick={(e) => {
                            console.log(e.target.value);
                            Axios.post(`${process.env.REACT_APP_API_URL}/api/pay`, {
                                transaction_token: e.target.value,
                                amount: Number(amount),
                                cin7Id: localStorage.getItem('cin7Id'),
                                email: localStorage.getItem('email'),
                                company: localStorage.getItem('company'),
                                reference: reference,
                            }).then((response) => {
                                console.log(response);
                                if (response.data.success) {
                                    //update amount owed
                                    //redirect to success
                                    window.location.replace('/payed');
                                } else {
                                    window.location.replace('/payedfail/'+response.data.errors[0].errorCode);
                                }
                            }).catch((err) => {
                                console.log(err);
                                window.location.replace('/payedfail/3002');
                            })
                        }}
                    />
                    <div className='span2'>
                        <label className='payment-field-label' htmlFor="card_holder">CARDHOLDER NAME</label>
                        <input className='payment-field w-100 pl-3 pr-3' placeholder='Name on card' required type="text" id="card_holder" name="card_holder"/>
                    </div>
                    <div className={`${isMobile ? 'payment-card-information flex flex-col mb-10' : 'payment-card-information flex flex-row'} justify-between w-100`}>
                        <div className={`flex flex-col ${isMobile ? 'payment-number-card-field w-100' : 'w-65 '}`}>
                            <label className='payment-field-label mt-4 mb-1'>
                                CARD NUMBER
                            </label>
                            <div id="number_div" className='w-100 cvv-card-field'></div>
                        </div>
                        <div className={`flex flex-row ${isMobile ? 'w-100' : 'justify-between w-30' }`}>
                            <div className={`flex flex-col ${isMobile ? 'w-25' : 'w-45'}`}>
                                <label className='payment-field-label mt-4 mb-1'>
                                    EXPIRY
                                </label>
                                <input id='expiry' placeholder='MM/YY' 
                                maxLength="5"
                                onChange={(e) => {
                                    let input = e.target.value;
                                    
                                    // Remove non-digit characters
                                    input = input.replace(/\D/g, ''); 
                            
                                    if (input.length > 2) {
                                        // Extract the first two digits for MM
                                        const month = input.substring(0, 2);
                            
                                        // Validate that the month should not be greater than 12
                                        if (parseInt(month, 10) > 12) {
                                            // If the month is greater than 12, trim it to 12
                                            input = `12/${input.substring(2, 4)}`;
                                        } else {
                                            // If the month is valid, add '/' after the first two digits
                                            input = `${month}/${input.substring(2, 4)}`;
                                        }
                                    }
                            
                                    // Set the formatted input value
                                    e.target.value = input;
                                }}
                                className='payment-field w-100 pl-3 pr-3'/>
                            </div>
                            <div className={`flex flex-col ${isMobile ? 'ml-4 w-25' : 'w-45'}`}>
                                <label className='payment-field-label mt-4 mb-1'>
                                    CVV
                                </label>
                                <div id="cvv_div" placeholder='CVV' className='w-100 cvv-card-field'/>
                            </div>
                        </div>
                        
                    </div>
                    <hr className='custom-hr'></hr>

                    <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} justify-between w-100 mt-10`}>
                        <div className={`flex flex-col  ${isMobile ? ' w-100 mb-10' : ' w-45'}`}>
                            <label className='payment-field-label mb-1' htmlFor="amount">INVOICE REFERENCE ID</label>
                            <input 
                                required
                                type="text" 
                                id="reference" 
                                name="reference" 
                                placeholder="HPHA11-53/6634" 
                                onChange={(e) => {
                                    setReference(e.target.value);
                                }}
                                className='payment-field w-100 pl-3 pr-3'
                            />
                        </div>
                        <div className={`flex flex-col  ${isMobile ? ' w-100' : ' w-45'}`}>
                            <label className='payment-field-label mb-1' htmlFor="amount">PAYMENT AMOUNT</label>
                            <input 
                                required 
                                className='pAmount payment-field w-100 pl-3 pr-3'
                                type="number" 
                                id="amount" 
                                name="amount" 
                                min={0}
                                placeholder="0.00" 
                                step="0.01" 
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    if (e.target.value !== '' || e.target.value !== null) {
                                        setAmount(e.target.value);
                                    } else {
                                        setAmount(0)
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='W-100 flex justify-center' style={{marginTop: "2rem"}}>
                        <input required disabled={true} className='button-submit-payment flex justify-center' type="submit" id='submit' value="SUBMIT"/>
                    </div>
                </form>
            </div>
            <br/><br/><br/>
        </div>
    );
} 

export default NewPayment