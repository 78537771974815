import React from 'react';
import { VscMail } from 'react-icons/vsc'
import { HiOutlineMapPin, HiOutlinePhone, HiOutlinePrinter } from 'react-icons/hi2'

import './nav.css';
const Footer2 = () => {

    return (
        <div className='footer2'>
            <div className='container'>
                <h1>Contact Us</h1>
                <ul className='footContain'>
                    {/* <li>
                        <a href='/contact' className='link'>Contact Us</a>
                    </li> */}
                    <li>
                        <VscMail size={18}/> <a href='mailto:info@theentourageeffect.com.au' className='link' > admin@midispensary.com.au</a>
                    </li>
                    <li>
                        <HiOutlinePhone size={18}/> <a href='tel:1300MiTime' className='link'>1300MiTime</a>
                    </li>
                    <li>
                        <HiOutlinePrinter size={18}/> <span className='link'>02 8823 1810</span>
                    </li>
                    <li>
                         <HiOutlineMapPin size={18}/> <span className='link'>PO BOX 799 Alexandria 1435</span>
                    </li>
                </ul>
                
            </div>
            
            <p style={{margin: '1rem', bottom: '0', position: 'absolute'}}>© 2023 The Entourage Effect</p>
        </div>
    )
}

export default Footer2