import React, { useEffect, useState } from 'react';
import Axios from 'axios'
import '../cart.css'
import '../newCart.css'
import { Form, Input, Checkbox, Spin } from 'antd';

const { TextArea } = Input;
const FormItem = Form.Item;
const CartPage = () => {

    const [products, setProducts] = useState(localStorage.getItem("Cart") ? JSON.parse(localStorage.getItem("Cart")) : []);
    const [message, setMessage] = useState('');
    const [tos, setTos] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [instructions, setInstructions] = useState('')
    const [reference, setReference] = useState('')
    const [update, setUpdate] = useState('');
    const [disable, setDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1130);
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1130);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const checkout = () => {
        if (tos) {
            setIsLoading(true)
            Axios.post('https://api.theentourageeffect.com.au/api/postSalesOrder', {
                id: localStorage.getItem('cin7Id'),
                products: products,
                totalPrice: totalPrice,
                instructions: instructions,
                reference: reference
            }).then((response)=>{
                localStorage.removeItem('Cart');
                window.location.replace('/check');
                setIsLoading(false)
                setDisable(false);
            }).catch((response) => {
                console.log(response);
                setDisable(false);
                setIsLoading(false)
            })
            setMessage('')
        } else {
            setMessage('Please agree to the terms of services before checking out');
            setDisable(false);
        }
    }

    const changeQty = (index) => {
        var temp = products;
        if (temp[index].quantity > 0) {

            setIsLoading(true)
            Axios.get(`${process.env.REACT_APP_API_URL}/api/product_v2`, {
            headers: {
                id: localStorage.getItem('cin7Id'),
                item: temp[index].id,
                qty: temp[index].quantity,
                authorization: localStorage.getItem('accessToken'),
            }
            }).then((response) =>{
                console.log(response.data)
                setIsLoading(false)
                temp[index].price = Number(response.data[0].Wholesale).toFixed(2)
                temp[index].totalPrice = (Number(response.data[0].Wholesale) * temp[index].quantity).toFixed(2);
                console.log(temp[index]);
                setProducts(temp);
                setUpdate(update + '1');
            })

        } else {
            setIsLoading(false)
            var temp2 = products;
            temp2.splice(index,1);
            setUpdate(update + '1');
            setProducts(temp2);
        }
        setIsLoading(false)
    }

    useEffect(() => {
        // localStorage.setItem('Cart', products);
        localStorage.setItem('Cart', JSON.stringify(products));
        window.dispatchEvent( new Event('storage'))
        if (products) {
            var temp = 0;
            var temp2 = 0;
            products.forEach(prod => { 
                console.log('discount', prod.discount)
                temp = temp + (Number(prod.totalPrice) - Number(prod.totalPrice)*(Number(localStorage.getItem('vip')/100)));
                temp2 = temp2 + (Number(prod.discount) * Number(prod.quantity));
            });
            setTotalPrice(temp.toFixed(2));
        }
        // console.log(products)
    },[products, update])

    const handleIncrement = (index, type) => {
        const temp = [...products];

        if (type === 'increment') {
            temp[index].quantity = temp[index].quantity < 99999 ? temp[index].quantity + 1 : temp[index].quantity;
        } else if (type !== 'increment' && temp[index].quantity > 0) {
            temp[index].quantity = temp[index].quantity - 1;
        }

        setProducts(temp);

        changeQty(index)
    }

    const handleInputValue = (index, value) => {
        const temp = [...products];

        if(value > 0) {
            temp[index].quantity = parseInt(Number(value), 10)
        } else {
            temp[index].quantity = 0
        }

        setProducts(temp);
    }

    const sumbitOnBlur = (index) => {
        if (products[index].quantity === 0) {
            const temp = [...products];
            temp.splice(index, 1);
            setProducts(temp);
        }
        changeQty(index)
    }


    //Display Product
    return (
        <div className={`${isMobile ? 'container-cart-page-mobile' : 'container-cart-page'} pt-48 pb-44`}>
            <Spin spinning={isLoading} fullscreen />
            <div className='cart-page-title'>
                CART
            </div>
            { message !== '' &&
                <div className='warning text-center'>
                    <div className='font-bold text-3xl'>Warning</div>
                    <div className='text-xl'>{message}</div>
                </div>
            }

            {
                isMobile ?
                <div className='cart-page-content-mobile flex flex-col'>
                    <div className='w-100 flex flex-col'>
                        <hr className='custom-line-break mt-10'/>
                        { products.map ((prod, index) => (
                            <div key={index} className='w-100 cart-page-data-mobile flex flex-col mt-6'>
                                <div className='w-100 flex flex-row mb-2'>
                                    <div className='cart-table-data-image'>
                                        <img src={prod.img} alt="" />
                                    </div>
                                    <div className='flex flex-col ml-4 p-2'>
                                        <div className='cart-table-data-product-name'>
                                            {prod.name}
                                        </div>
                                        <div className='cart-table-data-invoice-name mt-2'>
                                            {prod.tga ? prod.tga : ''} | {prod.doctor} {(prod.initial !== null && prod.initial !== "") ? (' - ' + prod.initial) : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className='w-100 flex flex-row mb-2 justify-between'>
                                    <div className='flex flex-col'>
                                        <div className='flex flex-row justify-between container-button-edit-stock-cart-mobile'>
                                            <button onClick={() => {handleIncrement(index, 'decrement')}} 
                                                className='button-edit-stock-cart w-25'
                                                disabled={isLoading}
                                            >
                                                -
                                            </button>
                                            <div className='button-edit-qty w-50'>
                                                <input type="text" min={0} max={8} className='w-100 text-center outline-none' value={prod.quantity} 
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const regex = /^[0-9.,-]*$/;
                                                    if (input === '' || regex.test(input)) {
                                                        const value = input === '' ? 0 : parseInt(input, 10);
                                                        const valueResult = isNaN(value) ? 0 : Math.min(value, 99999);
                                                        handleInputValue(index, valueResult)
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    sumbitOnBlur(index)
                                                }}
                                                />
                                            </div>
                                            <button onClick={() => {handleIncrement(index, 'increment')}}
                                                className='button-edit-stock-cart w-25'
                                                disabled={isLoading}
                                            >
                                                +
                                            </button>
                                        </div>
                                        <div className='cart-button-remove-product mt-2'>
                                            <button
                                                onClick={() => {
                                                        var temp = products;
                                                        temp.splice(index,1);
                                                        setUpdate(update + '1');
                                                        setProducts(temp);
                                                    }
                                                }
                                            >
                                                <u>Remove</u>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='flex flex-col'>
                                        <div className='unit-price-cart-mobile'>
                                            Unit price: ${prod.price}
                                        </div>
                                        <div className='cubtotal-price-cart-mobile'>
                                            Subtotal: ${(Number(prod.totalPrice) - Number(prod.totalPrice)*(Number(localStorage.getItem('vip')/100))).toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                                <hr className='custom-line-break mt-4'/>
                            </div>
                        ))}
                        <div className='flex flex-col w-100 justify-end items-end'>
                            <div className='flex-flex-col w-50 mt-4'>
                                <div className='flex flex-row w-100 justify-between'>
                                    <div className='text-right cart-table-footer-text-label'>
                                        SUBTOTAL:
                                    </div>
                                    <div className='text-right cart-table-footer-text'>
                                        {"$" + totalPrice}
                                    </div>
                                </div>
                                <div className='flex flex-row w-100 justify-between mt-2'>
                                    <div className='text-right cart-table-footer-text-label'>
                                        TAX 10%:
                                    </div>
                                    <div className='text-right cart-table-footer-text'>
                                        {"$" + totalPrice * (10/100)}
                                    </div>
                                </div>
                                <hr className='custom-line-break mt-4'/>
                                <div className='flex flex-row w-100 justify-between mt-2'>
                                    <div className='text-right cart-table-footer-text-label'>
                                        TOTAL:
                                    </div>
                                    <div className='text-right cart-table-footer-text'>
                                        {`$ ${Number(totalPrice * (10/100)) + Number(totalPrice)}`}
                                    </div>
                                </div>
                            </div>
                            <FormItem label='Pharmx Order Reference / Customer PO Reference:'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        className={`custom-label w-100 mt-6`}
                                    >
                                        <Input 
                                            value={reference}
                                            onChange={(e) => setReference(e.target.value)}
                                            required = {true}
                                        />
                            </FormItem>
                            <FormItem label='Delivery Instructions:'
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        className={`custom-label w-100`}
                                    >
                                        <TextArea 
                                            value={instructions}
                                            onChange={(e) => setInstructions(e.target.value)}
                                            required = {true}
                                        />
                            </FormItem>
                            <FormItem className='w-100'>
                                <Checkbox
                                    className='checkbox-subscribe mt-4'
                                    onChange={() => {setTos(!tos)}}
                                    checked={tos}
                                >
                                    I have read and agree to the 
                                    <a  target='_blank' className='text-cyan-400' rel='noreferrer' href='https://theentourageeffect.com.au/terms-of-use/'> Terms & Conditions </a> 
                                    and 
                                    <a  target='_blank' className='text-cyan-400' rel='noreferrer' href='https://theentourageeffect.com.au/privacy-policy/'> Privacy Policy</a>
                                </Checkbox>
                            </FormItem>
                            <div className='container-button-cart w-100 flex justify-end mt-8 pb-20'>
                                <button className='button-cart-checkout-mobile flex justify-center items-center'
                                    onClick={() => {
                                        setDisable(true);
                                        checkout();
                                    }}
                                    disabled={disable}
                                >
                                    PROCEED TO CHECKOUT
                                </button>
                                <button className='button-cart-continue-mobile mt-5 flex justify-center items-center'
                                    onClick={() => {
                                        window.location.replace('/list');
                                    }}
                                >
                                    CONTINUE SHOPPING
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                :

                <div className='cart-page-content p-8 mt-8'>
                    <table className='w-100'>
                        <thead>
                            <tr className='table-cart-header'>
                                <th className='text-left w-55'>PRODUCT</th>
                                <th className='w-15'>QTY</th>
                                <th className='w-15 text-right'>UNIT PRICE</th>
                                <th className='w-15 text-right'>SUBTOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            { products.map ((prod, index) => (
                                <tr key={index} className='cart-table-body'>
                                    <td className='flex flex-row w-55'>
                                        <div className='cart-table-data-image'>
                                            <img src={prod.img} alt="" />
                                        </div>
                                        <div className='flex flex-col ml-4 p-2'>
                                            <div className='cart-table-data-product-name'>
                                                {prod.name}
                                            </div>
                                            <div className='cart-table-data-invoice-name mt-2'>
                                                {prod.tga ? prod.tga : ''}
                                            </div>
                                            <div className='cart-table-data-invoice-name mt-1'>
                                                {prod.doctor} {(prod.initial !== null && prod.initial !== "") ? (' - ' + prod.initial) : ''}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='w-15'>
                                        <div className='flex flex-col'>
                                            <div className='flex flex-row justify-between container-button-edit-stock-cart'>
                                                <button onClick={() => {handleIncrement(index, 'decrement')}} 
                                                    className='button-edit-stock-cart w-25'
                                                    disabled={isLoading}
                                                >
                                                    -
                                                </button>
                                                <div className='button-edit-qty w-50'>
                                                    <input type="text" min={0} max={8} className='w-100 text-center outline-none' value={prod.quantity} 
                                                    onChange={(e) => {
                                                        const input = e.target.value;
                                                        const regex = /^[0-9.,-]*$/;
                                                        if (input === '' || regex.test(input)) {
                                                            const value = input === '' ? 0 : parseInt(input, 10);
                                                            const valueResult = isNaN(value) ? 0 : Math.min(value, 99999);
                                                            handleInputValue(index, valueResult)
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        sumbitOnBlur(index)
                                                    }}
                                                    />
                                                </div>
                                                <button onClick={() => {handleIncrement(index, 'increment')}}
                                                    className='button-edit-stock-cart w-25'
                                                    disabled={isLoading}
                                                >
                                                    +
                                                </button>
                                            </div>
                                            <div className='cart-button-remove-product mt-2'>
                                                <button
                                                    onClick={() => {
                                                            var temp = products;
                                                            temp.splice(index,1);
                                                            setUpdate(update + '1');
                                                            setProducts(temp);
                                                        }
                                                    }
                                                >
                                                    <u>Remove</u>
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td className='text-right cart-unit-price'>
                                        ${prod.price}
                                    </td>
                                    <td className='text-right cart-subtotal-price'>
                                        ${(Number(prod.totalPrice) - Number(prod.totalPrice)*(Number(localStorage.getItem('vip')/100))).toFixed(2)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td className='text-right cart-table-footer-text-label'>SUBTOTAL</td>
                                <td className='text-right cart-table-footer-text'>{"$" + totalPrice}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td className='text-right cart-table-footer-text-label cart-table-footer'>TAX 10%</td>
                                <td className='text-right cart-table-footer-text cart-table-footer'>{"$" + totalPrice * (10/100)}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td className='text-right cart-table-footer-text-label pt-6'>TOTAL</td>
                                <td className='text-right cart-table-footer-total-text pt-6'>{`$ ${Number(totalPrice * (10/100)) + Number(totalPrice)}`}</td>
                            </tr>
                        </tfoot>
                    </table>
                    <FormItem label='Pharmx Order Reference / Customer PO Reference:'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                className={`custom-label w-100`}
                            >
                                <Input 
                                    value={reference}
                                    onChange={(e) => setReference(e.target.value)}
                                    required = {true}
                                />
                    </FormItem>
                    <FormItem label='Delivery Instructions:'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                className={`custom-label w-100`}
                            >
                                <TextArea 
                                    value={instructions}
                                    onChange={(e) => setInstructions(e.target.value)}
                                    required = {true}
                                />
                    </FormItem>
                    <FormItem>
                        <Checkbox
                            className='checkbox-subscribe mt-4'
                            onChange={() => {setTos(!tos)}}
                            checked={tos}
                        >
                            I have read and agree to the 
                            <a  target='_blank' className='text-cyan-400' rel='noreferrer' href='https://theentourageeffect.com.au/terms-of-use/'> Terms & Conditions </a> 
                            and 
                            <a  target='_blank' className='text-cyan-400' rel='noreferrer' href='https://theentourageeffect.com.au/privacy-policy/'> Privacy Policy</a>
                        </Checkbox>
                    </FormItem>
                    <div className='container-button-cart w-100 flex justify-end mt-8'>
                        <button className='button-cart-checkout flex justify-center items-center'
                            disabled={disable}
                            onClick={() => {
                                setDisable(true);
                                checkout();
                            }}
                        >
                            PROCEED TO CHECKOUT
                        </button>
                        <button className='button-cart-continue mt-5 flex justify-center items-center'
                            onClick={() => {
                                window.location.replace('/list');
                            }}
                        >
                            CONTINUE SHOPPING
                        </button>
                    </div>
                </div>
            }
        </div>
    );
} 

export default CartPage