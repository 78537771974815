import React from 'react';
import './newProductCard.css';

const NewProductCard = (props) => {
    return (
        <div className='card-product-container flex flex-row'>
            <div className='card-product-content'>
                <div className='card-product-image'>
                    <img src={props.img} alt='logo' className='cardImg'/>
                </div>
                <div className='card-product-desc flex flex-col mt-3'>
                    <div className='card-product-brand-title'>
                        {props.brand ? props.brand : 'No Brand'}  |  {props.form ? props.form : 'No Form'}
                    </div>
                    <div className='card-product-name-title'>
                        {props.name}
                    </div>
                    <div className='card-product-price'>
                        $ {props.price}
                    </div>
                </div>
            </div>
        </div>
    );
} 

export default NewProductCard