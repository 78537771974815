import React from 'react';
import '../login.css'

const Check = () => {

    //Display Product
    return (
        <div className='container'>
            <div className='message center pt-44'>
                <div>
                    <h1>Thanks for ordering with The Entourage Effect</h1>
                    <h3 className='text'>Your order will be processed within 2 business days</h3>
                    <a className='login' href='list'>Back to products</a>
                </div>
            </div>
        </div>
    );
} 

export default Check