import React, { useState, useEffect } from 'react';
import Axios from 'axios'
import '../newLogin.css'
import logoSvg from '../../assets/the_entourage_logo_new.svg'
import partnerSvg from '../../assets/pharm_partner.svg'
import eyeHideSvg from '../../assets/eye-hide-login.svg'
import eyeShowSvg from '../../assets/eye-show-login.svg'
import { Spin } from 'antd';

const NewLoginPage = () => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [id, setId] = useState("");
    const [message, setMessage] = useState("");
    const [sentOTP, setSentOTP] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState ("");
    const [createPass, setCreatePass] = useState(false);
    const [loginViaPass, setLoginViaPass] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1130);
    const sponsorContext = require.context('../../assets/sponsors', false, /\.(jpg|jpeg|png|gif|svg)$/);
    const sponsorImagePaths = sponsorContext.keys().sort((a, b) => {
        const nameA = a.split('/').pop().split('.')[0];
        const nameB = b.split('/').pop().split('.')[0];
        return nameA.localeCompare(nameB);
    });
    const sponsorImages = sponsorImagePaths.map(sponsorContext);


    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1130);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    const sendOTP = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        setMessage("Loading...");
        Axios.get(`${process.env.REACT_APP_API_URL}/api/passwordExist`, {
            headers: {
                email: email, 
            }
        }).then((response)=> {
            setIsLoading(false)
            const r = response.data.exist;
            const lock = response.data.lock;
            if (r) {
                if (lock !== 'Y') {
                    setMessage("");
                    setLoginViaPass(true);
                    return;
                } else {
                    window.location.replace('/locked');
                }
            }
            setIsLoading(true)
            Axios.post(`${process.env.REACT_APP_API_URL}/api/sendOTP`, {
                email: email,
            }).then((response)=>{
                setIsLoading(false)
                setMessage("");
                if (response.data.message === "Email Sent Successfully!") {
                    setId(response.data.id);
                    setSentOTP(true);
                } else {
                    setMessage("Invalid Email!");
                }
            }).catch((response) => {
                setIsLoading(false)
            })
        })
    }

    const verifyOtp = () => {
        setMessage("verifying...")
        setIsLoading(true)
        Axios.post(`${process.env.REACT_APP_API_URL}/api/verifyOTP`, {
            email: email,
            otp: otp,
            id: id
        }).then((response)=> {
            setIsLoading(false)
            setMessage("");
            if (response.data !== "Incorrect credentials please try again!") {
                setMessage("")
                setCreatePass(true);
            } else {
                setMessage("Incorrect Password!");
            }
        })
    }

    const createPassword = (e) => {
        setIsLoading(true)
        e.preventDefault();
        if (pass !== confirmPass) {
            setMessage("Password must match!");
            return;
        }
        Axios.post(`${process.env.REACT_APP_API_URL}/api/createPassword`, {
            email: email, 
            pass: pass,
        }).then((response)=> {
            setMessage("");
            setIsLoading(false)
            if (response.data === 'ok') {
                setLoginViaPass(true);
            }
        })
    }

    const resetPassword = (e) => {
        setIsLoading(true)
        e.preventDefault();
        Axios.post(`${process.env.REACT_APP_API_URL}/api/resetPassword` , {
            email: email,
        }).then(async (response)=> {
            setIsLoading(false)
            setMessage("Password reset... login again to change password");
            await sleep(1000);
            window.location.reload(false);
        }).catch((err) => {
            setIsLoading(false)
            setMessage("failed")
        })
    }

    const login = (e) => {
        setIsLoading(true)
        e.preventDefault();
        setMessage("Loading...");
        Axios.post(`${process.env.REACT_APP_API_URL}/api/LoginN`, {
            email: email, 
            pass: pass,
        }).then((response)=> {
            setIsLoading(false)
            if (response.data === 'Incorrect credentials please try again!') {
                setMessage("Incorrect Password!");
            } else {
                setMessage("Logged in!");
                localStorage.setItem('accessToken', "Bearer " + response.data.accessToken);
                localStorage.setItem('cin7Id', response.data.id);
                localStorage.setItem('tier', response.data.tier);
                localStorage.setItem('vip', response.data.vip);
                localStorage.setItem('email', email);
                localStorage.setItem('company', response.data.company)
                localStorage.setItem('loyalty', response.data.loyalty)
                window.location.replace("/list");
            }
        })
    }


    //Display Product
    return (
        <div className='container-login pb-40'>
            <Spin spinning={isLoading} fullscreen />
            <div className='top-banner-login justify-center align-middle'>
                <div className='top-banner-logo'>
                    <img src={logoSvg} alt="" />
                </div>
                <div className='top-banner-input mt-20'>
                {!loginViaPass ? (
                <>
                    {!createPass ? (
                        <>
                        {!sentOTP ?
                            <div className='email-input-container flex flex-col enter-email'>
                                <div className='input-title'>
                                    CUSTOMER PORTAL LOGIN
                                </div>
                                <div className={`email-input flex justify-center`}>
                                    <input 
                                    autoComplete='username'
                                    onChange={(e) => setEmail(e.target.value)} 
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            sendOTP(e)
                                        }
                                    }}
                                    className={`email pl-5 pr-5 ${isMobile ? 'mobile-width' : 'desktop-width'}`} type="text" placeholder='Email Address' />
                                </div>
                                <div className='button-input flex flex-row space-x-8'>
                                    <button className='login-button-input' onClick={(e) => sendOTP(e)}>
                                        SIGN IN
                                    </button>
                                    <button className='register-button-input' onClick={(e) => {e.preventDefault(); window.location.replace("/register")}}>
                                        REGISTER
                                    </button>
                                </div>
                            </div>
                            :
                            <div className='email-input-container flex flex-col enter-otp'>
                                <div className='input-title'>
                                    ENTER OTP
                                </div>
                                <div className={`email-input flex justify-center`}>
                                    <input onChange={(e) => setOtp(e.target.value)} 
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            verifyOtp()
                                        }
                                    }}
                                    className={`otp pl-5 pr-5 ${isMobile ? 'mobile-width' : 'desktop-width'}`} type="text" placeholder='OTP' />
                                </div>
                                <div className='button-input flex flex-row space-x-8'>
                                    <button className='login-button-input' onClick={() => verifyOtp()}>
                                        CONTINUE
                                    </button>
                                </div>
                            </div>
                        }
                        </>)
                        :
                        <div className='email-input-container flex flex-col enter-pass'>
                            <div className='input-title'>
                                NEW PASSWORD:
                            </div>
                            <div className={`email-input flex justify-center`}>
                                <input onChange={(e) => setPass(e.target.value)} className={`password pl-5 pr-5 ${isMobile ? 'mobile-width' : 'desktop-width'}`} type="text" placeholder='New Password' />
                            </div>
                            <div className={`email-input flex justify-center`}>
                                <input onChange={(e) => setConfirmPass(e.target.value)} 
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        createPassword(e)
                                    }
                                }}
                                className={`password pl-5 pr-5 ${isMobile ? 'mobile-width' : 'desktop-width'}`} type="text" placeholder='Confirm Password' />
                            </div>
                            <h3 className='title'>{message}</h3>
                            <div className='button-input flex flex-row space-x-8'>
                                <button className='login-button-input' onClick={(e) => createPassword(e)}>
                                    CONTINUE
                                </button>
                            </div>
                        </div>
                    }
                </>)
                : (
                    <div className='email-input-container flex flex-col enter-email'>
                        <div className='input-title'>
                            CUSTOMER PORTAL LOGIN
                        </div>
                        <div className={`email-input flex justify-center`}>
                            <div className={`password-container ${isMobile ? 'mobile-width' : 'desktop-width'}`}>
                                <input onChange={(e) => setPass(e.target.value)} 
                                autoComplete="current-password"
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        login(e)
                                    }
                                }}
                                id="password-text-field"
                                className={`password password-input w-100 pl-5 pr-5`} type={showPassword ? 'text' : 'password'} placeholder='Password' required />
                                <button onClick={togglePasswordVisibility}>
                                    {
                                        showPassword ? 
                                            <img src={eyeShowSvg} className='icon-password' alt='' />
                                        :
                                            <img src={eyeHideSvg} className='icon-password' alt='' />
                                    }
                                </button>
                            </div>
                        </div>
                        <div className='button-input flex flex-row space-x-8'>
                            <button className='login-button-input' onClick={(e) => login(e)}>
                                SUBMIT
                            </button>
                            <button className='register-button-input pl-4 pr-4' onClick={(e) => {resetPassword(e)}}>
                                FORGOT PASSWORD
                            </button>
                        </div>
                    </div>
                    )
                }
                </div>
                <div className='top-banner-partner flex flex-col mt-20'>
                    <div className='title-partner'>
                        Proudly partnered with
                    </div>
                    <div className='title-image'>
                        <img src={partnerSvg} alt="" />
                    </div>
                </div>
            </div>
            <div className='banner-partner mt-16'>
                <div className='banner-partner-content'>
                    <div className='banner-partner-title mb-20'>
                        OUR PARTNERS
                    </div>
                    <div className='banner-partner-image flex flex-row flex-wrap justify-center'>
                        {sponsorImages.map((image, index) => (
                            <div key={index} className={`justify-center ${isMobile ? 'w-1/2 p-2' : 'w-1/5 p-2'}`}>
                                <img src={image} alt={`Sponsor ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
} 

export default NewLoginPage