import React, { useState, useEffect } from 'react';
import './newFooter.css';
import logoSvg from '../assets/the_entourage_logo_new.svg'
import phoneSvg from '../assets/phone_icon_footer.svg'
import emailSvg from '../assets/email_icon_footer.svg'
import bigLogoSvg from '../assets/footer_big_logo.svg'

const NewFooter = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1130);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1130);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={`footer-container ${isMobile ? 'h-276' : 'h-175'}`}>
            <div className={`footer-section-1 flex flex-row justify-between ${isMobile ? 'h-220' : 'h-154'}`}>
                <div className={`footer-logo-information flex ${isMobile ? 'flex-col' : 'flex-row space-x-20'}`}>
                    <div className={`footer-logo ${isMobile ? 'mb-4' : 'mb-0'}`}>
                        <img className='cursor-pointer' onClick={() => {window.location.replace("/list");}} src={logoSvg} alt="" />
                    </div>
                    <div className={`footer-information flex flex-col`}>
                        <div>
                            <ul>
                                <li className='flex flex-row justify-start align-middle'>
                                    <img src={phoneSvg} alt="" /> <a href='tel:1300CBDTHC' className='link'>1300CBDTHC</a>
                                </li>
                                <li className='flex flex-row justify-start align-middle'>
                                    <img src={emailSvg} alt="" /> <a href='mailto:info@theentourageeffect.com.au' className='link' > info@theentourageeffect.com.au</a>
                                </li>
                            </ul>
                        </div>
                        {
                            isMobile ? '' 
                            :
                            <div className='mt-4 company-info'>
                                Pharmx Supplier Id 1100982  <span className='mr-2 ml-2'>|</span>   ABN 26 650 002 127   <span className='mr-2 ml-2'>|</span>   © 2023 The Entourage Effect
                            </div> 
                        }
                    </div>
                </div>
                <div className='footer-big-logo'>
                    <img src={bigLogoSvg} alt="" />
                </div>
            </div>
            {
                isMobile ? 
                <div className='company-info footer-section-1 pl-1 pr-1 pb-4'>
                    Pharmx Supplier Id 1100982  <span className='mr-2 ml-2'>|</span>   ABN 26 650 002 127   <span className='mr-2 ml-2'>|</span>   © 2023 The Entourage Effect
                </div>
                :
                ''
            }
            <div className='footer-section-2'>
            </div>
        </div>
    )
}

export default NewFooter