import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NewProduct from '../NewProduct';
import Axios from 'axios'
import '../NewProductPage.css'
import { Spin } from 'antd';

const NewProductPage = () => {
    // Retrieve Product ID from search page
    let { id } = useParams();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1130);
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1130);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
      
    //Let Product Load
    const [isLoading, setIsLoading] = useState(true);
    const [mapList, setMapList] = useState([]);
    const [products, setProducts] = useState();
    const [subType, setSubType] = useState('');
    const [sales, setSales] = useState(0);
    const [exclList, setExclList] = useState([]);

    //Retrieve product
    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_API_URL}/api/product_v2`, {
            headers: {
                id: localStorage.getItem('cin7Id'),
                item: id,
                qty: 1,
                authorization: localStorage.getItem('accessToken'),
            }
        }).then(async (response) =>{
            // Axios.get(CREATE API CALL TO RETRIEVE LIST OF MAP NUMBERS RELATED TO ID)
            setProducts(response.data);
            setSubType(response.data[0].Product_Sub_Type)
            //AWAIT AXIOS GET DISCOUNT EXCL
            await Axios.get(`${process.env.REACT_APP_API_URL}/api/exclDiscount`, {
            }).then((response) => {
                delete response.data.recordset['Item_code'];
                setExclList(response.data.recordset);
            })
            await Axios.get(`${process.env.REACT_APP_API_URL}/api/salesPW`, {
                headers: {
                    code: response.data[0].Style_Code
                }
            }).then((response) => {
                setSales(response.data);
            })
            Axios.get(`${process.env.REACT_APP_API_URL}/api/getMapNo`, {
                headers: {
                    id: response.data[0].Style_Code,
                    sub_type: response.data[0].Product_Sub_Type
                }
            }).then((res) => {
                var temp = [];
                res.data.forEach(x => {
                    temp.push({value: x.map_ref, label: x.map_ref + " (" + x.med_pract + ((x.patient_initial !== null && x.patient_initial !== "") ? (" - " + x.patient_initial) : "") +")", doctor: x.med_pract, initial: x.patient_initial})
                })
                setMapList(temp.sort((a, b) => 
                    a.value.localeCompare(b.value)
                ));
                setIsLoading(false);
            })
        })
    }, [id]);

    //Wait for product to load
    if (isLoading) {
        return (
            <div className='container'> 
                <div className='message'>
                    <Spin spinning={isLoading} fullscreen />
                </div>
            </div>
        )
    }

    //Display Product
    return (
        <div className={`container-detail-product pt-20 ${isMobile ? 'pb-60' : 'pb-40'}`}>
            <div>
                { products.map ((prod, index) => ( 
                    <div key ={index}>
                        <NewProduct
                            id={id}
                            code={prod.Style_Code}
                            img={'http://go.cin7.com/webfiles/TheEntourageEffeAU/webpages/images/998159/'+prod.Image_1} 
                            price={Number(prod.Wholesale).toFixed(2)} 
                            rrp={Number(prod.Retail).toFixed(2)} 
                            name={prod.Product_Name}
                            brand={prod.Category.slice(0,-9)}
                            map={mapList}
                            subtype={subType}
                            stock={prod.Stock_Avail}
                            sales={sales.recordset[0]?.sales_pw}
                            expiry={(prod.Option_1)}
                            category={prod.Sub_Category}
                            form={prod.Product_Types}
                            type={prod.Product_Sub_Type == null ? '': prod.Product_Sub_Type}
                            exclDiscount={exclList}
                        />
                    </div>
                ))
                }
            </div>
        </div>
    );
} 

export default NewProductPage