import React, { useEffect } from 'react';
import LoginPage from './NewLoginPage';
import Sponsors from './Sponsors'


const Landing = () => {

    useEffect(() => {
        if (window.location.href.indexOf('portal') === -1) {
            console.log(window.location.href)
            window.location.replace('https://portal.theentourageeffect.com.au');
        }
    }, [])

    //Display Product
    return (
        <>
            <div className='container landing'>
            <br/>
                <div className='message center'>
                    <div>
                        <h1 className='text'>Welcome to The Entourage Effect's Customer Portal</h1>
                        <br/><br/>
                        {/* <a className='login' href="/login">Click here to login</a> */}
                    </div>
                </div>
            </div>
            <LoginPage className=''/>
            <Sponsors/>
        </>
    );
} 

export default Landing