import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import './newCard.css';
import Axios from 'axios'
import { Modal } from 'antd';

const NewProduct = (props) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1130);
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1130);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Back end info
    const [code, setCode] = useState("");
    const [mapType, setMapType] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [selectedFile, setSelectedFile] = useState(null);
    const [openModalCart, setOpenModalCart] = useState(false);

    const showModal = () => {
        setOpenModalCart(true);
    };

    const handleCancel = () => {
        setOpenModalCart(false);
    };

    //Sas-b info
    const [doctor, setDoctor] = useState("");
    const [initial, setInitial] = useState("");
    const [dob, setDob] = useState("");
    const [indication, setIndication] = useState("");
    
    // Front end stuff
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [newMap, setNewMap] = useState(false);
    const [activeMap, setActiveMap] = useState("map");
    const [activeSas, setActiveSas] = useState("map");
    const [disable, setDisable] = useState(false);
    const [messageColor, setMessageColor] = useState('warning')
    

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            border: 'black',
            color: 'black'
        }),
        control: (provided) => ({
            ...provided,
            margin: '15px 0 15px 0',
            padding: '5px',
            border: '1px solid black',
            width: '70%'
        }),
        menu: (provided, state) => ({
            ...provided,
            width: '70%'
        })
    }

    const mapTypeButton = (e) => {
        if (e === "m") {
            setMapType("m");
            setActiveMap('map active');
            setActiveSas('map');
        }
        if (e === "s") {
            setMapType("s");
            setActiveSas('map active');
            setActiveMap('map');
        }
        setSelectedFile(null);
    }

    // useEffect(() => {
    //     console.log(selectedFile === undefined);
        
    // },[selectedFile])

    const checkMap = () => {
        Axios.get(`${process.env.REACT_APP_API_URL}/api/getMap`, {
            headers: {
                "map": code,
            }
        }).then((response)=> {
            console.log(response)
            if (!response.data.exists) {
                cartButton()
            } else {
                setMessageColor("warning");
                setMessage(response.data.message);
                setDisable(false);
            }
        })
    }

    const cartButton = () => {
        // if (props.stock <= 0) {
        //     setMessage("Currently out of stock.");
        //     return
        // }
        
        if ((quantity !== "" && (mapType === 's' && code.length === 12) && mapType === 's' && code.slice(0, 2).toLowerCase() === "mb" && (mapType === 's' && code.substring(4, 5).toLowerCase() === "-") && ((code !== "" && doctor !== "" && initial !== "" && dob !== "" && indication !== "" && newMap && selectedFile !== null && selectedFile !== undefined) || props.map.length === 0)) ||
            (quantity !== "" && (mapType === 'm' && code.length === 13) && mapType === 'm' && code.slice(0, 3).toLowerCase() === "map" && (mapType === 'm' && code.substring(5, 6).toLowerCase() === "-") && ((code !== "" && doctor !== "" && newMap && selectedFile !== null && selectedFile !== undefined) || props.map.length === 0)) ||
            (quantity !== "" && ((code !== "" && !newMap) || props.map.length === 0))) {
            // var totalPrice = parseFloat(props.price) * parseFloat(quantity);
            var map_i;

            if (code.charAt(0).toLowerCase() === 'm' && code.charAt(1).toLowerCase() === 'a') {
                map_i = 'm';
            } else if (code.charAt(0).toLowerCase() === 'm' && code.charAt(1).toLowerCase() === 'b'){
                map_i = 's';
            } else {
                map_i = null;
            }
            
            if (quantity <= 0) {
                setMessageColor('warning')
                setMessage("Quantity must be greater than 0");
                return;
            }

            Axios.get(`${process.env.REACT_APP_API_URL}/api/product_v2`, {
            headers: {
                id: localStorage.getItem('cin7Id'),
                item: props.id,
                qty: quantity,
                authorization: localStorage.getItem('accessToken'),
            }
            }).then((response) =>{
                console.log(response.data)
                
                console.log("Quantity: " + quantity);
                console.log("Total Price: " + response.data[0].Wholesale * quantity);

                //DISABLE DISCOUNT WITH API CALL
                let discount = (Number(props.price)*(Number(localStorage.getItem('vip'))/100));

                props.exclDiscount.forEach((i) => {
                    if (i['Item_code'].includes(props.code)){
                        discount = 0;
                    }
                })
                
                console.log("Discount: " + discount)
                var item = [
                    {
                        id: props.id,
                        code: props.code,
                        name: props.name,
                        img: props.img,
                        price: Number(response.data[0].Wholesale).toFixed(2),
                        discount: discount,
                        quantity: quantity,
                        doctor: doctor,
                        initial: initial,
                        tga: code,
                        tga_i: map_i,
                        totalPrice: (response.data[0].Wholesale * quantity).toFixed(2)
                    },
                ]
                

                // API CALL - ADD TO NEW TABLE CALLED TGA MAP PENDING
                var formData = new FormData();
                formData.append("pdf", selectedFile);
                formData.append("name", (code + ".pdf"));
                formData.append("map", code);
                if (newMap) {
                    Axios.post(`${process.env.REACT_APP_API_URL}/api/postMap`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }).then((response)=> {
                        console.log(response);
                    }).catch((response) => {
                        console.log(response);
                    })

                    Axios.post(`${process.env.REACT_APP_API_URL}/api/postTga`, {
                        Headers: {
                            authorization: localStorage.getItem('accessToken'),
                        },
                        authorization: localStorage.getItem('accessToken'),
                        map: code,
                        doctor: doctor,
                        type: props.subtype,
                        initial: initial,
                        dob: dob,
                        indication: indication,
                    }).then((response)=>{
                        console.log(item);
                        var storedCart = JSON.parse(localStorage.getItem("Cart"))
                        if (!storedCart) {
                            localStorage.setItem("Cart", JSON.stringify(item));
                            console.log(localStorage.getItem("Cart"));
                            window.dispatchEvent( new Event('storage'));
                            // window.location.replace('/cart')
                            setMessageColor("success");
                            setMessage("Added to cart");
                            
                            return;
                        }
                        storedCart.push(item[0]);
                        localStorage.setItem("Cart", JSON.stringify(storedCart));
                        console.log(JSON.parse(localStorage.getItem("Cart")));
                        console.log(response.data);
                        setMessageColor("success");
                        setMessage("Added to cart");
                        
                        window.dispatchEvent( new Event('storage'));
                        // window.location.replace('/cart')
                        setDisable(false);
                        setSelectedFile(null);
                        document.getElementById('file').value = '';
                        console.log(document.getElementById('file'));
                    }).catch((response) => {
                        console.log(response);
                        setDisable(false);
                        setSelectedFile(null);
                        document.getElementById('file').value = '';
                        console.log(document.getElementById('file'));
                    })
                } else {
                    var storedCart = JSON.parse(localStorage.getItem("Cart"))
                    if (!storedCart) {
                        localStorage.setItem("Cart", JSON.stringify(item));
                        console.log(localStorage.getItem("Cart"));
                        window.dispatchEvent( new Event('storage'));
                        // window.location.replace('/cart')
                        setMessageColor("success");
                        setMessage("Added to cart");
                        
                        return;
                    }
                    storedCart.push(item[0]);
                    localStorage.setItem("Cart", JSON.stringify(storedCart));
                    console.log(JSON.parse(localStorage.getItem("Cart")));
                    console.log(item);
                    setMessageColor("success");
                    setMessage("Added to cart");
                    
                    window.dispatchEvent( new Event('storage'));
                    // window.location.replace('/cart')
                    setDisable(false);
                    setSelectedFile(null);
                    document.getElementById('file').value = '';
                    console.log(document.getElementById('file'));
                }
            })
        } else {
            setMessageColor("warning");
            setMessage("All required fields must be filled.");
            if ((mapType === 's' && code.length !== 12) || (mapType === 'm' && code.length !== 13)) {
                console.log(code.length)
                setMessageColor("warning");
                setMessage("Incorrect MAP Length");
            }
            if ((mapType === 's' && code.substring(4, 5).toLowerCase() !== "-") || (mapType === 'm' && code.substring(5, 6).toLowerCase() !== "-")) {
                console.log('MAP:', code.substring(4, 5).toLowerCase())
                console.log('SASB: ', code.substring(5, 6).toLowerCase())
                setMessageColor("warning");
                setMessage("Incorrect placement of -");
            }
            if ((mapType === 's' && code.substring(0, 2).toLowerCase() !== "mb") || (mapType === 'm' && code.substring(0, 3).toLowerCase() !== "map")) {
                setMessageColor("warning");
                setMessage("Please select correct MAP type");
            }
            if (!selectedFile) {
                setMessageColor("warning");
                setMessage("Missing MAP PDF");
            }
            
            setDisable(false);
            if (document.getElementById('file')) {
                document.getElementById('file').value = '';
            }
        }
    }

    useEffect(() => {
        var storedCart = JSON.parse(localStorage.getItem("Cart"))
        if (storedCart) {
            storedCart.forEach((i) => {
                if (i.id === props.id) {
                    setCode(i.tga);
                }
            })
        }
    },[props.id])

    useEffect(() => {
        if (quantity === 0) {
            setQuantity(1)
        }
    },[quantity])

    return (
        <div className='product-container'>
            <div className='message center'>
                { message !== '' &&
                    <div className={messageColor}>
                        <h2 style={{padding: '1rem'}}>{message}</h2>
                    </div>
                }
            </div>
            <div className={`flex ${isMobile ? 'product-detail-container-mobile flex-col justify-center' : 'product-detail-container  flex-row justify-between'} mt-40`}>
                <div className={`${isMobile ? 'product-detail-image-mobile' : 'product-detail-image'}`}>
                    <img src={props.img} alt='prod'/>
                </div>
                <div className={`${isMobile ? 'product-detail-desc-mobile' : 'product-detail-desc'}`}>
                    <div className='product-details'>
                        <div className='product-detail-name'>
                            {props.name}
                        </div>
                        <div className='product-detail-price'>
                            ${props.price}
                        </div>
                        <div className='product-detail-rrp mt-2'>
                            RRP - $ {props.rrp}
                        </div>
                        <br/>
                        <div className='product-detail-information flex flex-col'>
                            <div className='flex flex-row space-x-3'>
                                <div className='product-detail-information-title'>
                                    Brand
                                </div>
                                <div>
                                    {props.brand ? props.brand : '-'}
                                </div>
                            </div>

                            <div className='flex flex-row space-x-3'>
                                <div className='product-detail-information-title'>
                                    Form
                                </div>
                                <div>
                                    {props.form ? props.form : '-'}
                                </div>
                            </div>

                            <div className='flex flex-row space-x-3'>
                                <div className='product-detail-information-title'>
                                    Stock
                                </div>
                                <div>
                                {Number(props.stock) > 0 ? (
                                    <span>
                                    {Number(props.sales) > Number(props.stock) ? (
                                        <span>Low</span>
                                    ):
                                    (
                                        <span>Available</span>
                                    )}
                                    </span>
                                ) : (
                                    <span>Out of Stock</span>
                                )}
                                </div>
                            </div>

                            <div className='flex flex-row space-x-3'>
                                <div className='product-detail-information-title'>
                                    Expiry
                                </div>
                                <div>
                                    {props.expiry ? props.expiry : '-'}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* MAP INPUT */}
                    <div className='product-details'>
                        {/* REMOVE SEARCH BUTTON AND SHOW BOTH ADD TO CART AND TGA APPROVAL CODE. */}
                        {(props.subtype !== null && props.subtype !== '001') &&
                        <>
                            <div className='product-detail-map-title'>
                                MAP/SAS-B Number
                            </div>
                            <div className='product-detail-approval-title'>
                                Patients TGA Approval or Authorised Prescriber number written exactly as per TGA Approval
                            </div>
                        </>
                        }
                        {/* TWO BUTTONS FOR MAP OR SASB */}
                        {newMap ? 
                            (
                            <div>
                                <div>
                                    <button className={activeMap} onClick={() => mapTypeButton('m')}>MAP</button>
                                    <button className={activeSas} onClick={() => mapTypeButton('s')}>SAS-B</button>
                                    <p className='textPad'/><a href='#map' onClick={() => {setNewMap(false); setMapType(''); setDob(''); setCode(''); setDoctor(''); setSelectedFile(null); setIndication('')}}>Back to list.</a>
                                </div>
                                {mapType === 'm' &&
                                    <>
                                        <input 
                                            className='product-input'
                                            value={code}
                                            placeholder='MAP12-3456789 (Required)'
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                        <h2>Doctor's Name</h2>
                                        <input 
                                            className='product-input'
                                            type="text"
                                            placeholder='John Smith (Required)'
                                            onChange={(e) => setDoctor(e.target.value)}
                                        />
                                        <h2>Upload MAP PDF</h2>
                                        <input 
                                            className='product-input'
                                            type="file"
                                            id="file" name="file"
                                            accept="application/pdf"
                                            multiple
                                            onChange={(e) => setSelectedFile(e.target.files[0])}
                                        />
                                    </>
                                }
                                {mapType === 's' &&
                                    <>
                                        <input 
                                            className='product-input'
                                            value={code}
                                            placeholder='MB12-3456789 (Required)'
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                        <h2>Doctor's Name</h2>
                                        <input 
                                            className='product-input'
                                            type="text"
                                            placeholder='John Smith (Required)'
                                            onChange={(e) => setDoctor(e.target.value)}
                                        />
                                        <h2>Patients Initial</h2>
                                        <input 
                                            className='product-input'
                                            type="text"
                                            placeholder='TT (Required)'
                                            onChange={(e) => setInitial(e.target.value)}
                                        />
                                        <h2>Patients Date of Birth</h2>
                                        <input 
                                            className='product-input'
                                            type="date"
                                            placeholder='YYYY/MM/DD (Required)'
                                            onChange={(e) => setDob(e.target.value.replace(/-/g,""))}
                                        />
                                        <h2>Patients Condition</h2>
                                        <input 
                                            className='product-input'
                                            type="text"
                                            placeholder='Anxiety (Required)'
                                            onChange={(e) => setIndication(e.target.value)}
                                        />
                                        <h2>Upload SAS-B PDF</h2>
                                        <input 
                                            className='product-input'
                                            type="file"
                                            id="file" name="file"
                                            accept="application/pdf"
                                            multiple
                                            onChange={(e) => setSelectedFile(e.target.files[0])}
                                        />
                                    </>
                                }
                                
                            </div>
                            )
                            :
                            (
                            <>
                                {(props.subtype !== null && props.subtype !== '001') &&
                                    <div>
                                        <Select 
                                            styles={customStyles}
                                            className='select-map-detail-product'
                                            options={props.map}
                                            placeholder='MAP12-3456789 (Required)'
                                            onChange={
                                                (e) => {
                                                    setCode(e.value); 
                                                    setDoctor(e.doctor); 
                                                    setInitial(e.initial);
                                                }
                                            }
                                            onInputChange={(input) =>{
                                                if (input) {
                                                setMenuIsOpen(true);
                                                } else {
                                                setMenuIsOpen(false);
                                                }
                                            }}
                                            menuIsOpen={menuIsOpen}
                                        />
                                        <p>Can't find your map number? <a href='#add' className='add-map-product-btn' onClick={() => setNewMap(true)}>Add it here.</a></p>
                                    </div>
                                }

                            </>
                            )
                            
                        }
                        <div className='product-detail-add-cart flex flex-row mt-10'>
                            <div className='product-detail-add-cart-qty flex flex-row space-x-2'>
                                <button className='w-5' onClick={(e) => setQuantity(quantity <= 1 ? quantity : quantity - 1)}>
                                    -
                                </button>
                                <div className='product-detail-qty w-30'>
                                    <input type="text" min={1} className='w-100 text-center outline-none' value={quantity} 
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        const regex = /^[0-9]*$/;
                                        if (input === '' || regex.test(input)) {
                                            const value = input === '' ? 1 : parseInt(input, 10);
                                            const valueResult = isNaN(value) ? 1 : Math.min(value, 99999);
                                            setQuantity(valueResult);
                                        }
                                    }}/>
                                </div>
                                <button className='w-5' onClick={(e) => quantity < 99999 ? setQuantity(quantity + 1) : setQuantity(quantity)}>
                                    +
                                </button>
                            </div>
                            <button className='product-detail-add-cart-btn'
                                disabled={disable}
                                onClick={() => {
                                    showModal()
                                    setDisable(true);
                                    if (mapType === 's' || mapType ==='m') {
                                        checkMap();
                                    } else {
                                        cartButton();
                                    }
                                    setSelectedFile(null);
                                    window.scrollTo(0, 0);
                                }}
                            >
                                ADD TO CART
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="Different MAPS in Cart"
                open={openModalCart}
                onCancel={handleCancel}
                footer={[]}
            >
                <div className='mt-5 font-bold text-base'>
                    { JSON.parse(localStorage.getItem("Cart"))?.filter(x => x.id === props.id).reverse().map((prod, index) => {
                            return(
                                <div key={index}>
                                    <p className={index === 0 ? 'prevItems bold' : 'prevItems'} key={index}>{prod.name}{prod.tga === null ? " - " + prod.tga : ""} {prod.doctor === null ? "(" + prod.doctor : ""}{prod.tga_i === 's' && prod.tga_i !== null ? <> - {prod.initial})</> : ""} <span className='right'>x{prod.quantity}</span> </p>
                                    {index === 0 &&
                                        <hr className='line' />
                                    }
                                </div>
                            )
                    })}
                </div>
            
                <button className='proceed-to-cart-btn-modal mt-8' onClick={() => window.location.replace('/cart')}>Proceed to Cart</button>
            </Modal>
        </div>
    );
} 

export default NewProduct