import React from 'react';
import '../login.css'

const PayedMiD = () => {

    

    setTimeout(() => {
        const script = document.createElement('script');
        // TEST: nIDQlW9r38xQ0OlXOsB7
        // LIVE: i0lQd8qigfzk3NNzLATo
        script.type = 'text/javascript';
        script.text = 
        `
            (function(m,r,a,p,t,w,d){
                m['MRAptWidgetObject']=t;m[t]=m[t]||function(){m[t].q=(m[t].q||[]).concat(Array.prototype.slice.call(arguments))},w=r.createElement(a),d=r.getElementsByTagName(a)[0];w.async=1;w.src=p;w.id=t;d.parentNode.insertBefore(w,d)
            })(window,document,'script','https://widget.medirecords.com/scripts/widget.js','mraptWidget');
            mraptWidget('780f51cb-abcc-44a2-bcd8-dae8e45509b3', '0YC8Gsrn4dovwYwaxEm3aIwuDPE');
        `
        
        document.body.appendChild(script);
        console.log(document.head);
        return () => {
            document.body.removeChild(script);
        }
    }, 10)

    
    //Display Product
    return (
        <div className='container'>
            <div className='message center'>
                <div style={{width: '80%'}}>
                    <h1 className='text'>Thank you for choosing Endoca/MiDispensary</h1>
                    <h3 className='text' style={{textAlign: 'justify', textJustify: 'inter-word'}}>
                        Thank you for choosing Endoca/MiDispensary for your consultation. We're dedicated to providing exceptional healthcare services and ensuring your well-being. 
                        To make the booking process convenient for you, we offer two simple ways to schedule your consultation.<br/>
                        To book your consultation online, simply click the button below. If you have any questions or prefer to schedule your appointment over the phone, our friendly staff 
                        is here to assist you. Please call us at <a href='tel:1300CBDTHC' className='link'>1300 MiTime</a> for personal assistance. We're happy to help!<br/>
                        We strive to create a warm and welcoming environment, coupled with top-notch medical care. Our highly skilled healthcare professionals are committed to providing 
                        comprehensive consultations, and creating personalised treatment plans to help you on your path to wellness.<br/>
                    </h3>

                    {/* <h4 style={{color: 'red'}}>Online booking is currently unavailble, please call us at <a href='tel:1300CBDTHC' className='link2'>1300 MiTime</a>. Sorry for the inconvenience.</h4>
                    <button 
                        className='checkout half'
                        style={{height: "56px ", fontSize: '18px', color: '#e6e6e6', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px', cursor: 'not-allowed'}}
                        title='Online booking currently unavailble'
                        disabled={true}
                    >
                            Book Appointment
                    </button> */}
                    <div className='center'>
                        <div class="mrapt-widget"></div>
                    </div>
                </div>
            </div>
        </div>
    );
} 

export default PayedMiD