import React, { useState, useEffect } from 'react';
import './header.css';
import svgImage from '../assets/the_entourage_logo_new.svg'; 
import cartImage from '../assets/cart_header_icon.svg';
import loopSvg from '../assets/loop_search_header_icon.svg';
import Axios from 'axios';
const Header = ({onSearch}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1130);
    const [auth, setAuth] = useState("");
    const [cartNum, setCartNum] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('id');
        localStorage.removeItem('tier');
        localStorage.removeItem('loyalty');
        window.location.replace('/login');
    }

    function userAuthenticated()  {
        

        Axios.get(`${process.env.REACT_APP_API_URL}/api/authenticated`, {
            headers: {
                "authorization": localStorage.getItem("accessToken"),
            },
        }).then((response)=> {
            setAuth(response.data);
        }).catch((err)=> {
        });
    }

    const triggerSearchProduct = () => {
        onSearch(searchQuery)
    }

    useEffect(() => {
        userAuthenticated();
    },[auth]);

    useEffect(()=> {
        var cart = JSON.parse(localStorage.getItem("Cart")) || [];
        window.addEventListener('storage', () =>{
            cart = JSON.parse(localStorage.getItem("Cart")) || [];

            setCartNum(cart.length);
        });

        setCartNum(cart.length);
    }, [])

    useEffect(() => { 
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1130);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            { auth === 'authenticated' ? 
                <div className={`${isMobile ? 'header-container-mobile' : 'header-container'}`}>
                    <div className={`header-nav-1 flex flex-row ${isMobile ? 'justify-center p-0' : 'justify-end pr-36'} space-x-8`}>
                        <button className='flex flex-row header-nav-1-menu space-x-2'>
                            <button onClick={() => {window.location.replace('/payment')}} className='header-nav-1-title'>
                                Make Payment
                            </button>
                            <img className='header-nav-1-icon' src={require('../assets/make_payment_header_icon.png')} alt="" />
                        </button>
                        <button className='flex flex-row header-nav-1-menu space-x-2'>
                            <button onClick={() => {window.location.replace('/history')}} className='header-nav-1-title'>
                                Orders history
                            </button>
                            <img className='header-nav-1-icon' src={require('../assets/order_history_header_icon.png')} alt="" />
                        </button>
                        <button className='flex flex-row header-nav-1-menu space-x-2'>
                            <button className='header-nav-1-title' onClick={logout}>
                                Log out
                            </button>
                            <img className='header-nav-1-icon' src={require('../assets/logout_header_icon.png')} alt="" />
                        </button>
                    </div>
                    <div className={`${isMobile ? 'header-nav-2-mobile' : 'header-nav-2'}`}>
                        <div className={`header-nav-2-section-1 flex flex-row space-x-8 justify-between ${isMobile ? 'pl-10' : 'pl-36'}`}>
                            <img onClick={() => {window.location.replace("/list");}} className={`${isMobile ? 'header-nav-2-logo-img-mobile' : 'header-nav-2-logo-img'} cursor-pointer`} src={svgImage}  alt="" />
                            <div className={`header-nav-2-menu flex flex-row ${isMobile ? 'pr-10' : 'pr-10'} space-x-8`}>
                                <div onClick={() => {window.location.replace("/list");}} className='header-nav-2-menu-title cursor-pointer'>
                                    VIEW PRODUCTS
                                </div>
                                {
                                    isMobile ? 
                                    ''
                                    :
                                    <div className='header-nav-2-menu-search'>
                                        <input type="text" placeholder='Search products'
                                            onChange={(e) => {setSearchQuery(e.target.value)}}
                                            onKeyUp={(e) => {
                                                if (e.key === 'Enter') {
                                                    triggerSearchProduct();
                                                }
                                            }}
                                        />
                                        <button onClick={triggerSearchProduct}>
                                            <img src={loopSvg} alt="" />
                                        </button>
                                    </div>
                                }
                                <div className='header-nav-2-menu-cart-icon flex cursor-pointer'>
                                    <div className='cart-header-badge' id="cart-badge">
                                        {cartNum}
                                    </div>
                                    <img onClick={() => {window.location.replace('/cart')}} className='cart-header-img' src={cartImage}  alt="" />
                                </div>
                            </div>
                        </div>
                        {
                            isMobile ?
                                <div className={`header-nav-2-section-2 flex flex-row justify-center align-middle pl-10 pr-10`}>
                                    <div className='header-nav-2-menu-search-mobile'>
                                        <input type="text" placeholder='Search products'
                                            onChange={(e) => {setSearchQuery(e.target.value)}}
                                            onKeyUp={(e) => {
                                                if (e.key === 'Enter') {
                                                    triggerSearchProduct();
                                                }
                                            }}
                                        />
                                        <button onClick={triggerSearchProduct}>
                                            <img src={loopSvg} alt="" />
                                        </button>
                                    </div>
                                </div>
                            : ''
                        }
                    </div>
                </div>
                : ''
            }
        </div>
    )
}

export default Header