import React from 'react';
import '../login.css'

const Payed = () => {

    //Display Product
    return (
        <div className='container'>
            <div className='message center'>
                <div>
                    <h1 className='text'>Your payment is being processed.</h1>
                    <h3 className='text'>Your payment is being processed, Amount Owed will be updated once payment has been confirmed.</h3>
                    <h3 className='text'>Thank you for choosing The Entourage Effect.</h3>

                    <button 
                        className='checkout half'
                        onClick={() => {
                            window.location.replace('/list');
                        }}>
                            Return to products
                    </button>
                </div>
            </div>
        </div>
    );
} 

export default Payed