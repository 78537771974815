import Navbar from "./components/Header";
import Footer from "./components/NewFooter";
import ProductPage from "./components/pages/NewProductPage";
import ProductSearchPage from "./components/pages/NewProductSearchPage";
import CartPage from "./components/pages/NewCartPage";
import LoginPage from "./components/pages/NewLoginPage";
import AdminTest from "./components/pages/AdminTest";
import Landing from "./components/pages/Landing";
import Check from "./components/pages/Check";
import HistoryPage from "./components/pages/NewHistoryPage";
import ChangeBanner from "./components/pages/ChangeBanner";
import ReportBug from "./components/pages/ReportBug";
import Register from "./components/pages/NewRegister";
import Registered from "./components/pages/Registered";
import './index.css';

import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
// import Contact from "./components/pages/Contact";
import Tracking from "./components/pages/AddTrackingNumber";
import Payment from "./components/pages/NewPayment";
import Payed from "./components/pages/Payed";
import PayedFail from "./components/pages/PayedFail";
import Locked from "./components/pages/Locked";
import PaymentWithID from "./components/pages/PaymentWithID";
import PayedMiD from "./components/pages/PayedMiD";
import PayedFailMiD from "./components/pages/PayedFailMiD";
import { useEffect, useState } from "react";
// import Navbar2 from "./components/Navbar2";
import Footer2 from "./components/Footer2";
import RegisterPromo from "./components/pages/RegisterPromo";
import '@splidejs/react-splide/css';

function App() {
  const navigate = useNavigate();
  const location = useLocation()
  const [searchString, setSearchString] = useState(false);


  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');

    if (location.pathname === "/" && !accessToken) {
      navigate('/login');
    } else if (location.pathname === "/" && accessToken) {
      navigate('/list');
    }
  }, [navigate, location.pathname]);

  const handleSearch = (search) => {
    if (location.pathname === "/list") {
      setSearchString(search);
    } else {
      navigate("/list");
      setSearchString(search);
    }
  };

  return (
    <div className="App">
      {!location.pathname.includes('paymentmidxendoca') && !location.pathname.toLowerCase().includes('payedfailedmid') && !location.pathname.toLowerCase().includes('payedmid') && !location.pathname.toLowerCase().includes('login') ?
        <Navbar onSearch={handleSearch}/>
        :
        ''
      }
      <div className='AppContainer'>
        <Routes>
          <Route exact path="/" element={<Landing/>}/>
          <Route exact path="/list" element={<ProductSearchPage searchQuery={searchString}/>}/>
          <Route exact path="/check" element={<Check/>}/>
          <Route path ="/products">
            <Route path=":id" element={<ProductPage/>}/>
          </Route>
          <Route path ="/paymentmidxendoca">
            <Route path=":id" element={<PaymentWithID/>}>
              <Route path=":coupon" element={<PaymentWithID/>}/>
            </Route>
          </Route>
          <Route exact path="/cart" element={<CartPage/>}/>
          <Route exact path="/history" element={<HistoryPage/>}/>
          <Route exact path='/login' element={<LoginPage/>}/>
          <Route exact path='/test' element={<AdminTest/>}/>
          <Route exact path='/reportBug' element={<ReportBug/>}/>
          <Route exact path='/changebanner' element={<ChangeBanner/>}/>
          <Route exact path='/register' element={<Register/>}/>
          <Route exact path='/registerPromo' element={<RegisterPromo/>}/>
          <Route exact path='/registered' element={<Registered/>}/>
          {/* <Route exact path='/contact' element={<Contact/>}/> */}
          <Route exact path='/tracking' element={<Tracking/>}/>
          <Route exact path='/payment' element={<Payment/>}/>
          <Route exact path='/payed' element={<Payed/>}/>
          <Route exact path='/locked' element={<Locked/>}/>
          <Route path ='/payedFail'>
            <Route path=":id" element={<PayedFail/>}/>
          </Route>
          <Route exact path='/payedMiD' element={<PayedMiD/>}/>
          <Route path ='/payedFailedMiD'>
            <Route path=":token" element={<PayedFailMiD/>}>
              <Route path=":id" element={<PayedFailMiD/>}/>
            </Route>
          </Route>
        </Routes>
      </div>
      {!location.pathname.includes('paymentmidxendoca') && !location.pathname.toLowerCase().includes('paymentfailedmid') && !location.pathname.toLowerCase().includes('payedmid') ?
        <Footer/>
        :
        <Footer2/>
      }
    </div>
  );
}
export default App;
