import React, { useEffect, useState } from 'react';
import Axios from 'axios'

const Tracking = () => {

    const [trackingNo, setTrackingNo] = useState(null);
    const [orderRef, setOrderRef] = useState(null);
    const [message, setMessage] = useState('');
    const [disable, setDisable] = useState(false);
    const [messageColor, setMessageColor] = useState('');

    const update = (e) => {
        e.preventDefault();
        setDisable(true);
        Axios.post('https://api.theentourageeffect.com.au/api/setTracking', {
            cin7_ord_ref: orderRef,
            carr_sscc: trackingNo
        }).then((response)=> {
            console.log(response);
            if (response.data === 'success') {
                setMessageColor("success")
                console.log(orderRef, trackingNo)
                setMessage(orderRef + ' ' + trackingNo + " has been added to Database.");
                setDisable(false);
            } else {
                setMessageColor("warning")
                setMessage('There was an error uploading tracking number');
                setDisable(false)
            }
        }).catch((err)=> {
            setMessageColor("warning")
            setMessage(err);
            setDisable(false)
        })
    }
    
    useEffect(() => {
        
    }, [])

    //Display Product
    return (
        <>
            <div className='container landing'>
            <br/>
                <div className='message center'>
                    { message !== '' &&
                        <div className={messageColor}>
                            <h2 style={{padding: '1rem'}}>{message}</h2>
                        </div>
                    }
                </div>
                <div className='message center'>
                    <div>
                        <h1 className='text'>Enter Order Reference and Tracking Number</h1>
                        <form className='t register' onSubmit={(e) => update(e)}>
                                <div>
                                    <label className='title'>Order Reference: </label>
                                    <input
                                        className='inputField'
                                        placeholder='KCHE126-32'
                                        type='text'
                                        onChange={(e) => setOrderRef(e.target.value)}
                                        required = {true}  
                                    />
                                </div>
                                <div>
                                    <label className='title'>Tracking Number: </label>
                                    <input
                                        className='inputField'
                                        placeholder='366GP500049201000964505'
                                        type='text'
                                        onChange={(e) => setTrackingNo(e.target.value)}
                                        required = {true}  
                                    />
                                </div>
                                <input className='logButton' disabled={disable} type='submit'/>
                            </form>
                    </div>
                </div>
            </div>
        </>
    );
} 

export default Tracking