import React from 'react';
import { useParams } from 'react-router-dom';
import '../login.css'

const PayedFail = () => {
    let { id } = useParams();
    //Display Product
    return (
        <div className='container'>
            <div className='message center'>
                <div>
                    <h1 className='text'>An error has occured.</h1>
                    <h3 className='text'>There was an error when processing your payment with the code: {id}.</h3>
                    <h3 className='text'>Please double check your details, if this error presists please contact us with <a href='/reportBug' className='link2'>Report a Bug</a> or mail us at <a href='mailto:info@theentourageeffect.com.au' className='link2' > info@theentourageeffect.com.au</a></h3>

                    <button 
                        className='checkout half'
                        onClick={() => {
                            window.location.replace('/list');
                        }}>
                            Return to products
                    </button>
                </div>
            </div>
        </div>
    );
} 

export default PayedFail