import Axios from 'axios'
import React, { useState, useEffect } from 'react';
import '../register.css';
import { Form, Select, Input, Checkbox, Spin } from 'antd';

const { Option } = Select;
const FormItem = Form.Item;

const NewRegister = () => {
    //variables
    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1130);
    const [iama, setIama] = useState('Pharmacist')
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [post, setPost] = useState('');
    const [ref, setRef] = useState('');
    const [abn, setAbn] = useState('');
    const [pharmex, setPharmex] = useState(false);
    const [message, setMessage] = useState('');
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1130);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    //functions
    const reg = (e) => {
        e.preventDefault();
        setDisable(true);
        setIsLoading(true)
        Axios.post(`${process.env.REACT_APP_API_URL}/api/registerPharm`, {
                iama: iama,
                name: firstName + ' ' + lastName,
                phone: phone,
                email: email,
                company: company,
                address: address,
                city: city,
                state: state,
                post: post,
                abn: abn,
                ref: ref,
                pharmex: pharmex
            }).then((response)=>{
                window.location.replace('/registered');
                //redirect to application page THANK YOU FOR REGESTERING WITH THEENTOURAGEEFFECT PLEASE WAIT UP TO 48 HOURS FOR YOUR REGISTRATION TO BE REVIEWED. YOU WILL RECIEVE A CONFIRMATION EMAIL ONCE IT HAS BEEN APPROVED.
                setIsLoading(false)
                setDisable(false);
            }).catch((response) => {
                setMessage('An error has occured please try again.');
                setDisable(false);
                setIsLoading(false)
            })
        } 

    return (
        <>
            <div className='container-register pt-52 pb-40 flex flex-col justify-center'>
                <div className='register-title'>
                    REGISTER
                </div>
                <Spin spinning={isLoading} fullscreen />
                <div className={`${isMobile ? 'register-content-mobile' : 'register-content'} mt-5`}>
                    <form className='register-content-form mt-8' onSubmit={(e) => reg(e)}>
                        <div className='flex flex-row justify-between'>
                            <FormItem label='I AM A' required
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'TYPE is required' }]}
                                className={`custom-label ${isMobile ? 'w-100' : 'w-48'}`}
                            >
                                <Select defaultValue='Pharmacist' className='custom-select'
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setIama(e.target.value);
                                    }}
                                >
                                    <Option value='Pharmacist'>Pharmacist</Option>
                                    <Option value='Doctor'>Doctor</Option>
                                </Select>
                            </FormItem>
                        </div>
                        <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} justify-between content-field`}>
                            <FormItem label='FIRST NAME' required
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'FIRST NAME is required' }]}
                                className={`custom-label ${isMobile ? 'w-100' : 'w-48'}`}
                            >
                                <Input 
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required = {true}
                                />
                            </FormItem>

                            <FormItem label='LAST NAME'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'LAST NAME is required' }]}
                                className={`custom-label ${isMobile ? 'w-100' : 'w-48'}`}
                            >
                                <Input 
                                    onChange={(e) => setLastname(e.target.value)}
                                />
                            </FormItem>
                        </div>
                        <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} justify-between content-field`}>
                            <FormItem label='PHONE NUMBER' required
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'PHONE NUMBER is required' }]}
                                className={`custom-label ${isMobile ? 'w-100' : 'w-48'}`}
                            >
                                <Input 
                                    onChange={(e) => setPhone(e.target.value)}
                                    required = {true}
                                />
                            </FormItem>

                            <FormItem label='EMAIL' required
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                    {
                                        type: 'email', message: 'The input is not valid E-mail!',
                                    }, 
                                    { 
                                        required: true, message: 'EMAIL is required' 
                                    }
                                ]}
                                className={`custom-label ${isMobile ? 'w-100' : 'w-48'}`}
                            >
                                <Input 
                                    onChange={(e) => setEmail(e.target.value)}
                                    required = {true}
                                />
                            </FormItem>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <FormItem label='COMPANY' required
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'COMPANY is required' }]}
                                className={`custom-label ${isMobile ? 'w-100' : 'w-48'}`}
                            >
                                <Input 
                                    onChange={(e) => setCompany(e.target.value)}
                                    required = {true} 
                                />
                            </FormItem>
                        </div>
                        <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} justify-between content-field`}>
                            <FormItem label='STREET ADDRESS' required
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'STREET ADDRESS is required' }]}
                                className={`custom-label ${isMobile ? 'w-100' : 'w-48'}`}
                            >
                                <Input 
                                    onChange={(e) => setAddress(e.target.value)}
                                    required = {true} 
                                />
                            </FormItem>

                            <div className={`${isMobile ? 'w-100' : 'w-48'} flex flex-row justify-between`}>
                                <FormItem label='CITY' required
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[{ required: true, message: 'CITY is required' }]}
                                    className='custom-label w-40'
                                >
                                    <Input 
                                        onChange={(e) => setCity(e.target.value)}
                                        required = {true}  
                                    />
                                </FormItem>

                                <FormItem label='STATE' required
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[{ required: true, message: 'STATE is required' }]}
                                    className='custom-label w-24'
                                >
                                    <Input 
                                        onChange={(e) => setState(e.target.value)}
                                        required = {true}  
                                    />
                                </FormItem>

                                <FormItem label='POSTCODE' required
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    rules={[{ required: true, message: 'POSTCODE is required' }]}
                                    className='custom-label w-24'
                                >
                                    <Input 
                                        onChange={(e) => setPost(e.target.value)}
                                        required = {true}  
                                    />
                                </FormItem>
                            </div>
                        </div>
                        <div className={`flex ${isMobile ? 'flex-col' : 'flex-row'} justify-between content-field`}>
                            <FormItem label='WHERE DID YOU HEAR ABOUT US?' required
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                className={`custom-label ${isMobile ? 'w-100' : 'w-48'}`}
                            >
                                <Input placeholder='Affiliated pharmacy, Google, etc'
                                    onChange={(e) => setRef(e.target.value)}
                                    required = {true}  
                                />
                            </FormItem>

                            <FormItem label='PHARMACY ABN' required
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'PHARMACY ABN is required' }]}
                                className={`custom-label ${isMobile ? 'w-100' : 'w-48'}`}
                            >
                                <Input placeholder='XX XXX XXX XXX'
                                    onChange={(e) => setAbn(e.target.value)}
                                    required = {true}
                                />
                            </FormItem>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <FormItem>
                                <Checkbox
                                    className='checkbox-subscribe'
                                    onChange={() => {setPharmex(!pharmex)}}
                                >
                                    I would like to subscribe to PharmX invoice integration at $35AUD plus GST per month
                                </Checkbox>
                            </FormItem>
                        </div>
                        <h3 className='title'>{message}</h3>
                        <div className='flex flex-row justify-center mt-5'>
                            <FormItem>
                                <button disabled={disable} type='submit' className='custom-button-register'>
                                    SUBMIT
                                </button>
                            </FormItem>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
} 

export default NewRegister