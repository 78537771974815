import React, { useEffect, useState } from 'react';
import Axios from 'axios'
import Popup from '../Popup';
import '../historyOrder.css'
import FileDownload from 'js-file-download'
import { Spin } from 'antd';

const HistoryPage = () => {
    
    const [popupButton, setPopupButton] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const [messageColor, setMessageColor] = useState('');
    const [message, setMessage] = useState('');
    const [exclList, setExclList] = useState([]);
    const [unavailableItems, setUnavailabaleItems] = useState([]);
    const [owed, setOwed] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1130);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1130);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const downloadInvoice = (e, name, ref) => {
        setIsLoading(true);
        e.preventDefault();
        window.scrollTo(0, 0);
        setMessageColor('processing');
        setMessage('Invoice is downloading, this might take a while...');
        Axios({
            url:`${process.env.REACT_APP_API_URL}/api/downloadInvoice`,
            method:"GET",
            responseType:"blob",
            headers: {
                inv: name,
                ref: ref,
            }
        }).then((res) => {
            console.log(res.data);
            console.log(name);
            FileDownload(res.data, name+"_invoice.pdf");
            setMessageColor('success');
            setMessage(name + ' Invoice has successfully downloaded');
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            Axios({
                url:`${process.env.REACT_APP_API_URL}/api/downloadInvoice`,
                method:"GET",
                responseType:"blob",
                headers: {
                    inv: name,
                    ref: ref,
                }
            }).then((res) => {
                setIsLoading(false);
                FileDownload(res.data, name+"_invoice.pdf");
                setMessageColor('success');
                setMessage(name + ' Invoice has successfully downloaded');
            }).catch((err) => {
                setIsLoading(false);
                
                setMessageColor('warning');
                setMessage(name + ' Invoice download was unsuccessful, please try again');
            })
        })
    }

    const replaceCart = (order) => {
        console.log("THIS IS THE ORDER", order);
        let excluded = [];
        order.forEach((element, index) => {
            console.log('code', element.Cin7_Prod_Code);
            console.log('name', element.Prod_Name);
            console.log('img', element.Image);
            console.log('price', element.Prod_Cust_Cost);
            console.log('quantity', element.Order_Qty);
            console.log('doctor', element.Doctors_Name);
            console.log('initial', element.Patient_Initial);
            console.log('tga', element.MAP_Number);
            console.log('tga_i', element.MAP_Identifier);
            console.log('totalPrice', element.Order_Total_Cost);
            console.log('----------------------------------------------');

            let discount = (Number(element.Prod_Cust_Cost)*localStorage.getItem('vip')/100);
            setIsLoading(true);
            Axios.get(`${process.env.REACT_APP_API_URL}/api/product_v2`, {
            headers: {
                id: localStorage.getItem('cin7Id'),
                item: element.Cin7_Prod_Code,
                qty: element.Order_Qty,
                authorization: localStorage.getItem('accessToken'),
            }
            }).then((response) =>{
                setIsLoading(false);

                //adjust price.
                if (response.data.length > 0) {
                    var item = [
                        {
                            code: response.data[0].Style_Code,
                            name: response.data[0].Product_Name,
                            img: 'http://go.cin7.com/webfiles/TheEntourageEffeAU/webpages/images/998159/'+response.data[0].Image_1,
                            price: Number(response.data[0].Wholesale).toFixed(2),
                            discount: discount,
                            quantity: element.Order_Qty,
                            doctor: element.Doctors_Name,
                            initial: element.Patient_Initial,
                            tga: element.MAP_Number,
                            tga_i: element.MAP_Identifier,
                            totalPrice: (Number(response.data[0].Wholesale) * Number(element.Order_Qty)).toFixed(2)
                        },
                    ]

                    var storedCart = JSON.parse(localStorage.getItem("Cart"))
                    if (!storedCart) {
                        localStorage.setItem("Cart", JSON.stringify(item));
                        console.log(localStorage.getItem("Cart"));
                        window.dispatchEvent( new Event('storage'));
                        // window.location.replace('/cart')
                        return;
                    }
        
                    storedCart.push(item[0]);
                    localStorage.setItem("Cart", JSON.stringify(storedCart));
                    console.log(JSON.parse(localStorage.getItem("Cart")));
                    window.dispatchEvent( new Event('storage'));
                    console.log('ADDED')
                } else {
                    excluded.push(
                        [
                            {
                                code: element.Cin7_Prod_Code,
                                name: element.Prod_Name,
                                img: element.Image,
                                price: Number(element.Prod_Cust_Cost).toFixed(2),
                                discount: discount,
                                quantity: element.Order_Qty,
                                doctor: element.Doctors_Name,
                                initial: element.Patient_Initial,
                                tga: element.MAP_Number,
                                tga_i: element.MAP_Identifier,
                                totalPrice: (Number(element.Prod_Cust_Cost) * Number(element.Order_Qty)).toFixed(2)
                            },
                        ]
                    );
                    console.log('EXCLUDED')
                    console.log(excluded)
                }

                if (order.length-1 === index) {
                    console.log('EXCLUDED', excluded)
                    setUnavailabaleItems(excluded)
                    if (excluded.length > 0) {
                        setPopupButton(true);
                    } else {
                        setUnavailabaleItems(null)
                        // window.location.replace('/cart');
                    }
                }
            })
            exclList.forEach((i) => {
                if (i['Item_code'].includes(element.Cin7_Prod_Code)){
                    discount = 0;
                }
            })

        });
    }

    useEffect(() => {
        console.log(new Date().toISOString().slice(0, 19).replace('T', ' '));
        setIsLoading(true);
        Axios.get(`${process.env.REACT_APP_API_URL}/api/orderHistory`, {
            headers: {
                id: localStorage.getItem('cin7Id'),
            }
        }).then(async (response)=>{
            // console.log(response.data.history);
            setIsLoading(false);
            console.log(response.data.history,'<< data')
            var sorted = response.data.history.reduce(function(buckets,item){
                if(!buckets[item.Order_ID]) buckets[item.Order_ID] = [];
                buckets[item.Order_ID].push(item);
                return buckets;
            },{});
            
            var historyArr =[]
            for(var Order_ID in sorted) {
                // console.log(Order_ID)
                // console.log(sorted[Order_ID])
                historyArr.push(sorted[Order_ID]);
                // console.log(sorted)
            }
            setIsLoading(true);
            await Axios.get(`${process.env.REACT_APP_API_URL}/api/amountOwed`, {
                headers: {
                    id: localStorage.getItem('cin7Id'),
                } 
            }).then((response)=>{
                setOwed(response.data.balanceOwed)
                console.log(response.data.balanceOwed)
                setIsLoading(false);
                // console.log(response);
            }).catch((response) => {
                setIsLoading(false);
                console.log(response);
            })
            // console.log(historyArr)
            setIsLoading(true);
            await Axios.get(`${process.env.REACT_APP_API_URL}/api/exclDiscount`, {
            }).then((response) => {
                delete response.data.recordset['Item_code'];
                setExclList(response.data.recordset)
            })
            // await Axios.get('${process.env.REACT_APP_API_URL}/api/getInvoiceNo', {
            //     headers: {
            //         id: localStorage.getItem('cin7Id'),
            //     }
            // })
            // setHistory(JSON.stringify(sorted));
            setHistory(historyArr);
            setIsLoading(false);
        }).catch((response)=> {
            console.log(response);
            setIsLoading(false);
        })
    }, []);

    if (isLoading) {
        return <Spin spinning={isLoading} fullscreen />
    }

    return (
        <div className={`${isMobile ? 'history-order-container-mobile' : 'history-order-container' } pt-44 pb-44`}>
        <br/>
            <div className='history-order-title mb-12'>
                ORDERS HISTORY
            </div>
            <div className='center'>
                <div className='history-order-payment-information w-85'>
                    <div className={`w-100 ${isMobile ? 'history-order-payment-information-title-mobile' : '' }`}>
                        <div className='history-order-amount-due-title'>
                            Amount Due: ${owed ? 0.00.toFixed(2) : owed?.toFixed(2)}
                        </div>
                        <button className="history-order-pay-btn mt-4" onClick={() => window.location.href = '/payment'}>
                            MAKE PAYMENT
                        </button>

                    </div>
                </div>
            </div>
            <div className='message center'>
                { message !== '' &&
                    <div className={messageColor}>
                        <h2 style={{padding: '1rem'}}>{message}</h2>
                    </div>
                }
            </div>
            <div className={`history-order-data-information ${isMobile ? 'w-95 ml-mobile' : 'w-85 ml-desktop'} flex justify-center`}>
                <div className='w-100'>
                    {/* Loop per order */}
                    {
                        isMobile ?
                        history.map((prod, index) => {
                            return (
                                <div className='history-data-container p-8 mb-12' key={index}>
                                    <div className='history-data-header-mobile w-100 flex flex-row justify-between p-4'>
                                        <div className='w-45'>
                                            <div className='flex flex-row w-100'>
                                                <div className='w-50'>
                                                    Order placed:
                                                </div>
                                                <div className='w-50'>
                                                    {prod[0].Create_Date.substring(0,10)}
                                                </div>
                                            </div>
                                            <div className='flex flex-row  w-100'>
                                                <div className='w-50'>
                                                    Total:
                                                </div>
                                                <div className='w-50'>
                                                    ${prod.reduce((a,v) => a = a + v.Order_Total_Cost, 0)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-45'>
                                            <div className='flex flex-row  w-100'>
                                                <div className='w-50'>
                                                    Order no:
                                                </div>
                                                <div className='w-50'>
                                                    {prod[0].Cin7_Ord_Ref}
                                                </div>
                                            </div>
                                            <div className='flex flex-row  w-100'>
                                                <div className='w-50'>
                                                    Invoice no:
                                                </div>
                                                <div className='w-50'>
                                                    {prod[0].invoice_no === null ? 'Pending' : prod[0].invoice_no}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='history-data-header-button flex justify-between w-100 mt-4'>
                                        <button className='w-32'
                                            onClick={() => {
                                                replaceCart(prod)
                                            }}
                                        >
                                            REORDER
                                        </button>
                                        <button className='w-32'
                                            onClick={(e) => {
                                                downloadInvoice(e, prod[0].Cin7_Ord_Ref, prod[0].Cin7_Ord_Ref_ID)
                                            }}
                                        >
                                            DOWNLOAD INVOICE
                                        </button>
                                        <button className='w-32'
                                            disabled = {prod[0].carr_sscc === null}
                                            title= {prod[0].carr_sscc === null ? 'Tracking not available on this order' : ''}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                window.open('https://auspost.com.au/mypost/track/#/details/'+prod[0].carr_sscc,'_blank')
                                            }}
                                        >
                                            TRACK SHIPMENT
                                        </button>
                                    </div>
                                    {prod.map((item, index) => {
                                        return (
                                            <div className='w-100 flex flex-col mt-4'>
                                                <div className='w-100 history-product-name-mobile'>
                                                    {item.Prod_Name}
                                                </div>

                                                <div className='w-100 history-doctor-name-mobile mt-2'>
                                                    {item.MAP_Number}  |  {item.Doctors_Name} {item.MAP_Identifier === "s" && item.Patient_Initial !== "" ? (" (" + item.Patient_Initial + ")") : ""}
                                                </div>

                                                <div className='w-100 history-qty-mobile mt-2 flex flex-row justify-between'>
                                                    <div>
                                                        Qty: {item.Order_Qty}
                                                    </div>
                                                    <div>
                                                        Unit Price: ${Number(item.Prod_Cust_Cost)}
                                                    </div>
                                                    <div>
                                                        Subtotal: ${Number(item.Order_Qty)*Number(item.Prod_Cust_Cost)}
                                                    </div>
                                                </div>

                                                <hr className='mt-4'/>
                                            </div>
                                        )
                                    })}
                                    <div className='history-data-total-mobile w-100 text-right mt-2'>
                                        Total: ${prod.reduce((a,v) => a = a + v.Order_Total_Cost, 0)}
                                    </div>
                                    <hr className='mt-2'/>
                                </div>
                            )
                        })
                        :
                        history.map((prod, index) => {
                            return (
                                <div className='history-data-container p-8 mb-12' key={index}>
                                    <div className='history-data-header flex flex-row justify-between'>
                                        <div className='history-data-header-title flex flex-row justify-between w-45'>
                                            <div className='flex flex-col'>
                                                <div className='history-data-header-title-label'>
                                                    ORDER PLACED
                                                </div>
                                                <div className='history-data-header-title-data'>
                                                    {prod[0].Create_Date.substring(0,10)}
                                                </div>
                                            </div>
                                            
                                            <div className='flex flex-col'>
                                                <div className='history-data-header-title-label'>
                                                    ORDER N0.
                                                </div>
                                                <div className='history-data-header-title-data'>
                                                    {prod[0].Cin7_Ord_Ref}
                                                </div>
                                            </div>

                                            <div className='flex flex-col'>
                                                <div className='history-data-header-title-label'>
                                                    INVOICE NO.
                                                </div>
                                                <div className='history-data-header-title-data'>
                                                    {prod[0].invoice_no === null ? 'Pending' : prod[0].invoice_no}
                                                </div>
                                            </div>

                                            <div className='flex flex-col'>
                                                <div className='history-data-header-title-label'>
                                                    TOTAL
                                                </div>
                                                <div className='history-data-header-title-data'>
                                                    ${prod.reduce((a,v) => a = a + v.Order_Total_Cost, 0)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='history-data-header-button flex justify-between w-45'>
                                            <button className='w-32'
                                                onClick={() => {
                                                    replaceCart(prod)
                                                }}
                                            >
                                                REORDER
                                            </button>
                                            <button className='w-32'
                                                onClick={(e) => {
                                                    downloadInvoice(e, prod[0].Cin7_Ord_Ref, prod[0].Cin7_Ord_Ref_ID)
                                                }}
                                            >
                                                DOWNLOAD INVOICE
                                            </button>
                                            <button className='w-32'
                                                disabled = {prod[0].carr_sscc === null}
                                                title= {prod[0].carr_sscc === null ? 'Tracking not available on this order' : ''}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    window.open('https://auspost.com.au/mypost/track/#/details/'+prod[0].carr_sscc,'_blank')
                                                }}
                                            >
                                                TRACK SHIPMENT
                                            </button>
                                        </div>
                                    </div>
                                    <div className='history-data-content'>
                                        <table className='history-data-content-table w-100 mt-8'>
                                            <thead>
                                                <tr>
                                                    <th className='w-45 text-left'>PRODUCT</th>
                                                    <th className='w-11 text-left'>MAP</th>
                                                    <th className='w-11 text-left'>DOCTOR</th>
                                                    <th className='w-11 text-right'>QTY</th>
                                                    <th className='w-11 text-right'>UNIT PRICE</th>
                                                    <th className='w-11 text-right'>SUBTOTAL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {prod.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className='w-45 text-left'>{item.Prod_Name}</td>
                                                            <td className='w-11 text-left'>{item.MAP_Number}</td>
                                                            <td className='w-11 text-left'>{item.Doctors_Name} {item.MAP_Identifier === "s" && item.Patient_Initial !== "" ? (" (" + item.Patient_Initial + ")") : ""}</td>
                                                            <td className='w-11 text-right'>{item.Order_Qty}</td>
                                                            <td className='w-11 text-right'>${Number(item.Prod_Cust_Cost)}</td>
                                                            <td className='w-11 text-right'>${Number(item.Order_Qty)*Number(item.Prod_Cust_Cost)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className='table-history-footer-total-title text-right'>TOTAL</td>
                                                    <td className='table-history-footer-total-data text-right'>${prod.reduce((a,v) => a = a + v.Order_Total_Cost, 0)}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Popup trigger={popupButton} setTrigger={setPopupButton}>
                <div>
                    <h1 className='title'>Unavailable Items</h1>
                    <h3 className='title'>Following items are no longer available and was not added to cart: </h3>
                    { 
                        unavailableItems.map((prod, index) => {
                        return (
                            <p key={index} className='title'>{prod[0].name}</p>
                        )
                    })}
                    
                    <button className='product-button right' onClick={() => window.location.replace('/cart')}>Proceed to Cart</button>

                </div>
            </Popup>
        </div>
    );
} 

export default HistoryPage