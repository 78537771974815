import React, { useEffect, useState } from 'react';
import Axios from 'axios'
import '../login.css'

const AdminTest = () => {

    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState("");
    const [list, setList] = useState([]);
    const [id, setId] = useState("");
    const [message, setMessage] = useState("");

    const setAccount = () => {
        console.log(document.getElementById("e").value);
        var index = document.getElementById("e").value;
        if (index === '-1') { 
            setEmail("");
            setId("");
            return
        }
        console.log(index);
        setEmail(list[document.getElementById("e").value].email);
        setId(list[document.getElementById("e").value].id);
    }

    const adminLogin = () => {
        if (email.length > 0) {
            Axios.post('https://api.theentourageeffect.com.au/api/adminLogin', {
                email: email,
            }).then((response)=> {
                localStorage.setItem('accessToken', "Bearer " + response.data.accessToken);
                localStorage.setItem('cin7Id', id);
                console.log(email);
                setMessage("Logged in as " + email);
            })
        } else {
            setMessage("Select email");
        }


    }

    useEffect(() => {
        Axios.get('https://api.theentourageeffect.com.au/api/getEmails', {
        }).then((response)=>{
            setMessage("");
            setList(response.data);
            console.log(response.data);
            setLoading(false);
        }).catch((response)=> {
            console.log(response);
        })
    }, []);

    if (loading) {
        return <div className='container'> Loading... </div>
    }

    //Display Product
    return (
        <div className='container'>
            <div className='center'>
                <div className='loginDiv'>
                    <div className=''>
                        <h1 className='title'>INJECT DATA</h1>
                        <label className='subtitle'>Email: </label> 
                        <select name='e' id='e' onChange={() => setAccount()}>
                            <option className='inputField' key='-1' value='-1'>Choose Email</option>
                            {list.map((item, index) => (
                                <option key={index} className='inputField' value={index}> {item.email}</option>
                            ))}
                        </select>
                        <br/>
                        <button className='logButton' onClick={() => adminLogin()}> Login (admin) </button>
                        <button className='logButton' onClick={() => window.location.replace('/list')}> Products </button>
                        <h3 className='title'>{message}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
} 

export default AdminTest