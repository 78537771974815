import Axios from 'axios';
import React, { useEffect, useState } from 'react';
const ChangeBanner = () => {
    const [isLoading, setLoading] = useState(true);
    const [bannerColor, setBannerColor] = useState('');
    const [bannerText, setBannerText] = useState('');
    const [message, setMessage] = useState('');
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        Axios.get("https://api.theentourageeffect.com.au/api/banner", {
        }).then((response)=> {
            console.log(response.data[0])
            setBannerColor(response.data[0].color.trim());
            setBannerText(response.data[0].text.trim());
            setLoading(false);
        })
    }, [])

    const ChangeBanner = (e) => {
        e.preventDefault();
        console.log(bannerText);
        setDisable(true)
        Axios.post('https://api.theentourageeffect.com.au/api/updateBanner', {
                text: bannerText,
                color: bannerColor,
            }).then((response)=>{
                console.log(response);
                setMessage('Successfully updated');
                setDisable(false)
            }).catch((response) => {
                console.log(response);
                setMessage('Update Failed, Try again')
                setDisable(false);
            })
    }
    
    if (isLoading) {
        return (
            <div className='container'> 
                <div className='message'>
                    <h1>{message}</h1>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='container landing'>
            <br/>
                <div className='message center'>
                    <div>
                        <h1 className='text'>Change Banner</h1>
                        <form 
                            style={{width: '600px', padding: '1rem', fontSize: '20px', fontWeight: 'bold'}} 
                            onSubmit={(e) => ChangeBanner(e)}
                        >
                            <div style={{padding: '1rem', marginBottom: '6rem'}}>
                                <label style={{padding: '0.5rem', float: 'left'}}>
                                    Text: 
                                </label>
                                <textarea
                                    style={{width: '400px', height: '100px', padding: '0.5rem', float: 'right', resize: 'none'}}
                                    value={bannerText}
                                    onChange={(e) => {setBannerText(e.target.value)}}
                                />

                            </div>
                            <div style={{padding: '1rem', marginBottom: '1rem'}}>
                                <label style={{padding: '0.5rem', float: 'left'}}>
                                    Color: 
                                </label>
                                <textarea
                                    style={{width: '400px', padding: '0.5rem', float: 'right', resize: 'none'}}
                                    value={bannerColor}
                                    onChange={(e) => {setBannerColor(e.target.value)}}
                                />
                            </div>
                            <input 
                                style={{width: '100%', height: '30px', marginTop: '2rem'}}
                                type='submit'
                                disabled={disable}
                            />
                        </form>
                        <h2>{message}</h2>
                        <h1 className='text'>Preview</h1>
                    </div>
                </div>
                <div className='message center'>
                    <div className='banner' style={{backgroundColor: bannerColor}}>
                        <div className='marquee'>
                            <div>{bannerText}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
} 

export default ChangeBanner