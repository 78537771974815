import React from 'react';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import './carousel.css'

const Carousel = (props) => {

  return (
    <Splide aria-label="My Favorite Images"
        options={ {
            type: "loop",
            drag: "free",
            arrows: false,
            pagination: false,
            perPage: 3,
            gap: '20px',
            autoScroll: {
                pauseOnHover: false,
                pauseOnFocus: false,
                rewind: false,
                speed: 1
            }
        } }
        extensions={{ AutoScroll }}
    >
        {props.data.map((item, index) => (
            <SplideSlide
                key={index}
                style={{ backgroundColor: item.color}}
                className='carousel-card'
            >
            <div>
                {item.text.trim()}
            </div>
            </SplideSlide>
        ))}
    </Splide>
  );
};

export default Carousel;
