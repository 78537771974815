import Axios from 'axios'
import React, { useState } from 'react';

const RegisterPromo = () => {
    //variables
    const [iama, setIama] = useState("Pharmacist")
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [post, setPost] = useState("");
    const [ref, setRef] = useState("July23SendgridPromo");
    const [abn, setAbn] = useState("");
    const [pharmex, setPharmex] = useState(false);
    const [message, setMessage] = useState("");
    const [disable, setDisable] = useState(false);

    //functions
    const reg = (e) => {
        e.preventDefault();
        setDisable(true);
        Axios.post('https://api.theentourageeffect.com.au/api/registerPharm', {
                iama: iama,
                name: name,
                phone: phone,
                email: email,
                company: company,
                address: address,
                city: city,
                state: state,
                post: post,
                abn: abn,
                ref: ref,
                pharmex: pharmex
            }).then((response)=>{
                console.log(response);
                window.location.replace("/registered");
                //redirect to application page THANK YOU FOR REGESTERING WITH THEENTOURAGEEFFECT PLEASE WAIT UP TO 48 HOURS FOR YOUR REGISTRATION TO BE REVIEWED. YOU WILL RECIEVE A CONFIRMATION EMAIL ONCE IT HAS BEEN APPROVED.
                
                setDisable(false);
            }).catch((response) => {
                console.log(response);
                setMessage('An error has occured please try again.');
                setDisable(false);
            })
        } 

    return (
        <>
            <div className='container landing' style={{marginBottom: '100px'}}>
            <br/>
                <div className='message center'>
                    <div style={{maxWidth: '35%', minWidth: '500px'}}>
                        <h1>
                        The Widest Range of Medicinal Cannabis Products in Australia
                        </h1>
                        <br></br>
                        <ul style={{textAlign: 'left'}}>
                            <li>
                                ✅ Largest Selection of Premium Medicinal Cannabis Brands in Australia
                            </li>
                            <li>
                                ✅ Same Day dispatch on orders received before 3.00 pm
                            </li>
                            <li>
                                ✅ Fast distribution across all Australia with Next day delivery    

                            </li>
                            <li>
                                ✅ NO shipping fees
                            </li>
                            <li>
                            ✅ NO minimum order
                            </li>
                            <li>
                            ✅ Innovative Pharmacy Portal providing 24/7 ordering, order reviews, MAP access
                            </li>
                            <li>
                                ✅ PharmX integration making ordering as simple as 1, 2, 3
                            </li>
                            <li>
                                ✅ Dedicated Hotline to answer your queries – 1300 CBD THC 
                            </li>
                            <li>
                                ✅ Flexible Payment Terms 
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='message center'>
                    <div style={{maxWidth: '35%', minWidth: '500px'}}>
                        <h2>5% discount on all orders for the first 3 months</h2>
                        <p>At The Entourage Effect, our mission is to give you the widest range of products, simplify the ordering process, free up your resources and expedite your orders.</p>
                    </div>
                </div>
                <div className='message center'>
                    <img src = {require('../../assets/big.png')} alt='test'></img>
                </div>
                <div className='message center'>
                <div className='loginDiv' style={{width: '40%'}}>
                    <div>
                        <h1 className='title'>Register</h1>
                            <form className='t register' onSubmit={(e) => reg(e)}>
                                <div>
                                    <label className='title'>I am a: </label>
                                    <select className='selectField' onChange={(e) => {
                                        e.preventDefault();
                                        setIama(e.target.value);
                                    }}>
                                        <option value='Pharmacist'>Pharmacist</option>
                                        <option value='Doctor'>Doctor</option>
                                    </select>
                                </div>
                                <div>
                                    <label className='title'>Name: </label>
                                    <input
                                        className='inputField'
                                        placeholder='John Doe'
                                        type='text'
                                        onChange={(e) => setName(e.target.value)}
                                        required = {true}  
                                    />
                                </div>
                                <div>
                                    <label className='title'>Phone: </label>
                                    <input
                                        className='inputField'
                                        placeholder='0412345678'
                                        type='text'
                                        onChange={(e) => setPhone(e.target.value)}
                                        required = {true}  
                                    />
                                </div>
                                <div>
                                    <label className='title'>Email: </label>
                                    <input
                                        className='inputField'
                                        placeholder='John.Doe@email.com'
                                        type='text'
                                        onChange={(e) => setEmail(e.target.value)}
                                        required = {true}  
                                    />
                                </div>
                                <div>
                                    <label className='title'>Company: </label>
                                    <input
                                        className='inputField'
                                        placeholder='John Doe Co'
                                        type='text'
                                        onChange={(e) => setCompany(e.target.value)}
                                        required = {true}  
                                    />
                                </div>
                                <div>
                                    <label className='title'>Business Address: </label>
                                    <input
                                        className='inputField'
                                        placeholder='123 Street'
                                        type='text'
                                        onChange={(e) => setAddress(e.target.value)}
                                        required = {true}  
                                    />
                                </div>
                                <div>
                                    <label className='title'>City: </label>
                                    <input
                                        className='inputField'
                                        placeholder='Sydney'
                                        type='text'
                                        onChange={(e) => setCity(e.target.value)}
                                        required = {true}  
                                    />
                                </div>
                                <div>
                                    <label className='title'>State: </label>
                                    <input
                                        className='inputField'
                                        placeholder='NSW'
                                        type='text'
                                        onChange={(e) => setState(e.target.value)}
                                        required = {true}  
                                    />
                                </div>
                                <div>
                                    <label className='title'>Postcode: </label>
                                    <input
                                        className='inputField'
                                        placeholder='2000'
                                        type='text'
                                        onChange={(e) => setPost(e.target.value)}
                                        required = {true}  
                                    />
                                </div>
                                <div>
                                    <label className='title' hidden='true'>Where did you <br/> hear about us: </label>
                                    <input
                                        className='inputField'
                                        placeholder='Affiliated Pharmacy, Google, etc'
                                        hidden= 'true'
                                        type='text'
                                        onChange={(e) => setRef(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <label className='title'>Pharmacy ABN: </label>
                                    <input
                                        className='inputField'
                                        placeholder='12 345 678 999'
                                        type='text'
                                        onChange={(e) => setAbn(e.target.value)}
                                        required = {true}
                                    />
                                </div>
                                <p>
                                    <input className='check' type='checkbox' id='t&c' name='t&c' value='' onChange={() => {setPharmex(!pharmex)}}/>
                                        I would like to subscribe to pharmx invoice integration at $35AUD plus gst per month
                                </p>
                                <h3 className='title'>{message}</h3>
                                <input className='logButton' disabled={disable} type='submit'/>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
} 

export default RegisterPromo