import React from 'react';
import { useParams } from 'react-router-dom';
import '../login.css'

const PayedFailMiD = () => {
    let { id, token } = useParams();
    //Display Product
    return (
        <div className='container'>
            <div className='message center'>
                <div>
                    <h1 className='text'>An error has occured.</h1>
                    <h3 className='text'>There was an error when processing your payment with the code: {id}.</h3>
                    <h3 className='text'>Please double check your payment details</h3>

                    <button 
                        className='checkout half'
                        style={{height: "56px ", fontSize: '24px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}
                        onClick={() => {
                            window.location.replace('/paymentmidxendoca/'+token+'/ENDOCA');
                        }}>
                            Return to Payment
                    </button>
                </div>
            </div>
        </div>
    );
} 

export default PayedFailMiD