import React from 'react';
import '../login.css'

const Locked = () => {
    //Display Product
    return (
        <div className='container'>
            <div className='message center'>
                <div>
                    <h1 className='text'>Your Account has been temporarily locked</h1>
                    <h3 className='text'>
                        Please contact us through <a href='tel:1300CBDTHC' className='link2'>1300CBDTHC </a> 
                        or mail us at <a href='mailto:info@theentourageeffect.com.au' className='link2' > info@theentourageeffect.com.au </a> 
                        to unlock your account
                    </h3>

                    <button 
                        className='checkout half'
                        onClick={() => {
                            window.location.replace('/');
                        }}>
                            Back to main
                    </button>
                </div>
            </div>
        </div>
    );
} 

export default Locked