import Axios from 'axios';
import React, { useState, useEffect, useCallback } from 'react';
import useScript from '../useScript';
import { useParams } from 'react-router-dom';

const PaymentWithID = () => {
    let { id, coupon } = useParams();
    
    //Store info
    const [color, setColor] = useState('');
    const [warning, setWarning] = useState('');
    const [couponCode, setCouponCode] = useState(null);
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [gender, setGender] = useState('')
    const [dob, setDob] = useState('')
    // const [age, setAge] = useState('')
    // const [homePhone, setHomePhone] = useState('')
    // const [workPhone, setWorkPhone] = useState('')
    const [mobilePhone, setMobilePhone] = useState('')
    const [medicare, setMedicare] = useState('')
    const [expiry, setExpiry] = useState('')
    const [email, setEmail] = useState('')

    const cost = 95

    const checkCoupon = useCallback((c) => {
        console.log(c)
        Axios.get('https://api.theentourageeffect.com.au/api/checkCoupon', {
                headers: {
                    coupon: couponCode !== null ? couponCode : c,
                }
            }).then((response)=>{
                console.log(response.data)
                if (response.data.valid) {
                    setColor('success')
                    setWarning('Coupon Valid')
                    if(response.data.data[0].percent === 'Y') {
                        setCouponDiscount(95 * (response.data.data[0].discount/100))
                    } else {
                        setCouponDiscount(response.data.data[0].discount)
                    }
                    window.scrollTo(0, 0);
                } else {
                    setColor('warning')
                    setWarning('Coupon Invalid')
                    setCouponDiscount(0)
                    window.scrollTo(0, 0);
                }
                // console.log(response);
            }).catch((response) => {
                console.log(response);
            })
    },[couponCode]) 

    //Add payment-js
    useScript("https://code.jquery.com/jquery-3.6.1.min.js");
    // useScript("https://test-gateway.tillpayments.com/js/integrated/payment.1.3.min.js");
    useScript("https://gateway.tillpayments.com/js/integrated/payment.1.3.min.js");
    
    useEffect(() => {
        if (coupon && couponCode === null) {
            document.getElementById("coupon").value = coupon
            setCouponCode(coupon)
            checkCoupon(coupon);
        }
    },[coupon, checkCoupon, couponCode])

    useEffect(() => {
        if (id.length > 0) {
            console.log(id)
            Axios.get('https://api.theentourageeffect.com.au/api/getCustomerDetail', {
                headers: {
                    id: id,
                    test: 'test',
                }
            }).then((response)=>{
                setName(response.data.data[0].name)
                setAddress(response.data.data[0].address)
                setGender(response.data.data[0].gender)
                setDob(response.data.data[0].dob)
                // setAge(response.data.data[0].age)
                // setHomePhone(response.data.data[0].homePhone)
                // setWorkPhone(response.data.data[0].workPhone)
                setMobilePhone(response.data.data[0].mobilePhone)
                setMedicare(response.data.data[0].medicare)
                setExpiry(response.data.data[0].expiry)
                setEmail(response.data.data[0].email)

                console.log(response.data.data)

                document.getElementById("name").value = response.data.data[0].name;
                document.getElementById("address").value = response.data.data[0].address
                document.getElementById("gender").value = response.data.data[0].gender
                document.getElementById("dob").value = response.data.data[0].dob
                // document.getElementById("age").value = response.data.data[0].age
                // document.getElementById("home_phone").value = response.data.data[0].homePhone
                // document.getElementById("work_phone").value = response.data.data[0].workPhone
                document.getElementById("mobile_phone").value = response.data.data[0].mobilePhone
                document.getElementById("medicare").value = response.data.data[0].medicare
                document.getElementById("expiry").value = response.data.data[0].expiry
                document.getElementById("email").value = response.data.data[0].email
                // console.log(response);
            }).catch((response) => {
                console.log(response);
            })
        }

        setTimeout(() => {
            const script = document.createElement('script');
            // TEST: nIDQlW9r38xQ0OlXOsB7
            // LIVE: i0lQd8qigfzk3NNzLATo
            script.type = 'text/javascript';
            script.text = 
            `
                var payment = new PaymentJs();
                payment.init('i0lQd8qigfzk3NNzLATo', 'number_div', 'cvv_div', function(payment) {
                    var style = {
                        'border': '1px solid #73ba9b',
                        'background-color': '#73ba9b',
                        'color': '#ffffff',
                        'padding': '15px 15px 15px',
                        'font-size': '18px',
                        'width': '100%',
                        'height': '56px',
                        'line-height': 1.3,
                        '-webkit-border-radius': '10px',
                        '-moz-border-radius': '10px',
                        'border-radius': '10px',
                    };
                    
                    $('#number_div').css({"border": '', 'border-radius': '10px', 'background-color': '#ffffff'});
                    $('#cvv_div').css({"border": '', 'border-radius': '10px', 'background-color': '#ffffff'});
                    payment.setNumberStyle(style);
                    payment.setCvvStyle(style);
                    $('#payment_div').css({"pointer-events": '', 'opacity': ''});
                    $('#submit').prop("disabled", false);
                });  
    
                function interceptSubmit(e) {
                    e.preventDefault();
                    $('#submit').prop("disabled", true);
                    console.log('hey');
                    var data = {
                        card_holder: $('#card_holder').val(),
                        month: $('#exp_month').val(),
                        year: $('#exp_year').val(),
                    };
                    payment.tokenize(
                        data, //additional data, MUST include card_holder (or first_name & last_name), month and year
                        function(token, cardData) { //success callback function
                            $('#transaction_token').val(token); //store the transaction token
                            $('#payment_form').submit(function(){
                            });
                            $('#transaction_token').click();
                            $('#submit').prop("disabled", false);
                        }, 
                        function(errors) { //error callback function
                            alert('Error occurred: required field empty');
                            $('#submit').prop("disabled", false);
                            console.log(errors);
                            //render error information here
                        }
                    );
                }
            `
            
            document.body.appendChild(script);
            console.log(document.head);
            return () => {
                document.body.removeChild(script);
            }
        }, 2000)
    },[id])

    //Display Product
    return (
        <>
            <div className='container landing'>
            <br/>
                    { warning !== '' &&
                    <div className='message center'>
                        <div className={color}>
                            <h2 style={{padding: '1rem'}}>{warning}</h2>
                        </div>
                    </div>
                    }
                <div className='message center'>
                    <div>
                        <h1>MiDispensary Sign Up</h1>
                        <h2>Amount Due: ${cost - couponDiscount} {couponDiscount > 0 ? "(Originally $95)" : ""}</h2>
                    </div>
                </div>
                <div className='message center' id="payment_div" style={{pointerEvents: 'none', opacity: '0.6'}}>
                <div style={{width: '70%', minWidth:'900px'}}>
                    <form className='t contact' id="payment_form" action='' method="POST" onSubmit={(e) => {window.interceptSubmit(e); return false;}}>
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}}>
                            <label className='paylabel' htmlFor="name">Full Name</label>
                            <input
                                required
                                type="text" 
                                id="name" 
                                name="name" 
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                                style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}
                            />
                        </div>
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}}>
                            <label className='paylabel' htmlFor="gender">Gender</label>
                            <input
                                required
                                type="text" 
                                id="gender" 
                                name="gender" 
                                onChange={(e) => {
                                    setGender(e.target.value);
                                }}
                                style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}
                            />
                        </div>
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}} className='span2'>
                            <label  className='paylabel' htmlFor="email">Email</label>
                            <input
                                required
                                type="text" 
                                id="email" 
                                name="email" 
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}
                            />
                        </div>
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}} className='span2'>
                            <label  className='paylabel' htmlFor="address">Address</label>
                            <input
                                required
                                type="text" 
                                id="address" 
                                name="address" 
                                onChange={(e) => {
                                    setAddress(e.target.value);
                                }}
                                style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}
                            />
                        </div>
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}} className='span2'>
                            <label  className='paylabel' htmlFor="dob">Date of Birth</label>
                            <input
                                required
                                type="text" 
                                id="dob" 
                                name="dob" 
                                onChange={(e) => {
                                    setDob(e.target.value);
                                }}
                                style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}
                            />
                        </div>
                        {/* <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}}>
                            <label  className='paylabel' htmlFor="age">Age</label>
                            <input
                                required
                                type="number" 
                                id="age" 
                                name="age" 
                                onChange={(e) => {
                                    setAge(e.target.value);
                                }}
                                style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}
                            />
                        </div> */}
                        {/* <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}} className='span2'>
                            <label  className='paylabel' htmlFor="home_phone">Home Phone</label>
                            <input
                                required
                                type="text" 
                                id="home_phone" 
                                name="home_phone" 
                                onChange={(e) => {
                                    setHomePhone(e.target.value);
                                }}
                                style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}
                            />
                        </div>
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}} className='span2'>
                            <label  className='paylabel' htmlFor="work_phone">Work Phone</label>
                            <input
                                required
                                type="text" 
                                id="work_phone" 
                                name="work_phone" 
                                onChange={(e) => {
                                    setWorkPhone(e.target.value);
                                }}
                                style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}
                            />
                        </div> */}
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}} className='span2'>
                            <label className='paylabel' htmlFor="mobile_phone">Mobile Phone</label>
                            <input
                                required
                                type="text" 
                                id="mobile_phone" 
                                name="mobile_phone" 
                                onChange={(e) => {
                                    setMobilePhone(e.target.value);
                                }}
                                style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}
                            />
                        </div>
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}}>
                            <label  className='paylabel' htmlFor="medicare">Medicare</label>
                            <input
                                required
                                type="text" 
                                id="medicare" 
                                name="medicare" 
                                onChange={(e) => {
                                    setMedicare(e.target.value);
                                }}
                                style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}
                            /> 
                        </div>
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}}>
                            <label  className='paylabel' htmlFor="expiry">Expiry</label>
                            <input
                                required
                                type="text" 
                                id="expiry" 
                                name="expiry" 
                                onChange={(e) => {
                                    setExpiry(e.target.value);
                                }}
                                style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}
                            />
                        </div>
                        <div className='span2' style={{margin: "1.5rem 0.5rem 1.5rem 0.5rem"}}>
                            <label className='paylabel' htmlFor="coupon">Coupon</label>
                            <input 
                                required
                                type="text" 
                                id="coupon" 
                                name="coupon" 
                                placeholder="" 
                                onChange={(e) => {
                                    setCouponCode(e.target.value);
                                }}
                                onBlur={(e) => {
                                    checkCoupon(e.target.value);
                                }}
                                style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}/>
                        </div>
                        <br/><br/>
                        <input 
                            type="hidden" 
                            name="transaction_token" 
                            id="transaction_token"
                            onClick={(e) => {
                                console.log(e.target.value);
                                function getAge(dateString) {
                                    var today = new Date();
                                    var birthDate = new Date(dateString);
                                    var age = today.getFullYear() - birthDate.getFullYear();
                                    var m = today.getMonth() - birthDate.getMonth();
                                    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                                        age--;
                                    }
                                    return age;
                                }
                                if (
                                        (name !== '' || name !== null ) &&
                                        (address !== '' || address !== null ) &&
                                        (gender !== '' || gender !== null ) &&
                                        (dob !== '' || dob !== null ) &&
                                        (mobilePhone !== '' || mobilePhone !== null ) &&
                                        (medicare !== '' || medicare !== null ) &&
                                        (expiry !== '' || expiry !== null ) &&
                                        (email !== '' || email !== null ) &&
                                        (coupon !== '' || coupon !== null )
                                    ) {
                                        Axios.post('https://api.theentourageeffect.com.au/api/MidispensaryPay', {
                                            transaction_token: e.target.value,
                                            name: name,
                                            address: address,
                                            gender: gender,
                                            dob: dob,
                                            age: getAge(dob),
                                            homePhone: '',
                                            workPhone: '',
                                            mobilePhone: mobilePhone,
                                            medicare: medicare,
                                            expiry: expiry,
                                            email: email,
                                            amount: Number(cost - couponDiscount),
                                            coupon: couponCode !== null ? couponCode : coupon,
                                            reference: id,
                                        }).then((response) => {
                                            console.log(response);
                                            if (response.data.success) {
                                                //update amount owed
                                                //redirect to success
                                                window.location.replace('/payedMiD');
                                            } else {
                                                window.location.replace('/payedFailedMiD/'+id+'/'+response.data.errors[0].errorCode);
                                            }
                                        }).catch((err) => {
                                            console.log(err);
                                            window.location.replace('/payedfail/3002');
                                        })
                                } else {
                                    setColor('warning');
                                    setWarning('Please fill in missing fields');
                                    window.scrollTo(0, 0);
                                }
                            }}
                        />
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}} className='span2'>
                            <label className='paylabel' htmlFor="card_holder">Card holder</label>
                            <input required type="text" id="card_holder" name="card_holder" style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}/>
                        </div>
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}} className='span2'>
                            <label className='paylabel' htmlFor="number_div">Card number</label>
                            <div required id="number_div" style={{height: "56px ", fontSize: '18px', color: '#ffffff', backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px', marginTop: '19px'}}></div>
                        </div>
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}} className='span2'>
                            <label className='paylabel' htmlFor="cvv_div">CVV</label>
                            <div required id="cvv_div" style={{height: "56px ", fontSize: '18px', color: '#ffffff', backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px', marginTop: '19px'}}></div>
                        </div>
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}}>
                            <label className='paylabel' htmlFor="exp_month">Month</label>
                            <input required type="text" id="exp_month" name="exp_month" style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}/>
                        </div>
                        <div style={{margin: '1.5rem 0.5rem 1.5rem 0.5rem'}}>
                            <label className='paylabel' htmlFor="exp_year">Year</label>
                            <input required type="text" id="exp_year" name="exp_year" style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}/>
                        </div>
                        <div className='span2' style={{margin: "1.5rem 0.5rem 1.5rem 0.5rem"}}>
                            <input required disabled={true} className='button blue' type="submit" id='submit' value="Submit" style={{height: "56px ", fontSize: '18px', color: '#ffffff', padding: "15px 15px 15px", backgroundColor: '#73ba9b', border: '1px solid #73ba9b', borderRadius: '10px'}}/>
                        </div>
                    </form>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                </div>
            </div>
        </>
    );
} 

export default PaymentWithID