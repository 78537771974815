import Axios from 'axios';
import React, { useEffect, useState } from 'react';
const ReportBug = () => {
    const [isLoading, setLoading] = useState(true);
    const [text, setText] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState('');
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, [])

    useEffect(() => {
        console.log(selectedFile)
    },[selectedFile])
    
    const reportBug = (e) => {
        e.preventDefault();
        setDisable(true);
        setMessage('Sending...');
        var formData = new FormData();
        formData.append("pdf", selectedFile);
        formData.append("text", text);
        formData.append("email", localStorage.getItem('email'));
        formData.append("id", localStorage.getItem('cin7Id'))
        Axios.post('https://api.theentourageeffect.com.au/api/bugReport', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response)=> {
            console.log(response);
            setMessage('Report Sent!');
            setDisable(false);
        }).catch((response) => {
            console.log(response);
            setMessage('Failed to send report.');
            setDisable(false);
        })
    }

    if (isLoading) {
        return (
            <div className='container'> 
                <div className='message'>
                    <h1>{message}</h1>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='container landing'>
            <br/>
                <div className='message center'>
                    <div>
                        <h1 className='text'>Report Bug</h1>
                        <form 
                            style={{width: '600px', padding: '1rem'}} 
                            onSubmit={(e) => reportBug(e)}
                        >
                            <label>Please explain what steps you took to lead to the bug or problem and attach a screenshot of the problem.</label>

                            <div style={{padding: '1rem', marginBottom: '6rem', display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
                                <label style={{padding: '0.5rem', float: 'left', fontSize: '20px',  fontWeight: 'bold'}}>
                                    Bug Report: 
                                </label>
                                <br/>
                                <textarea
                                    style={{width: '400px', height: '100px', padding: '0.5rem', float: 'right', resize: 'none'}}
                                    value={text}
                                    onChange={(e) => {setText(e.target.value)}}
                                />
                            </div>
                            <input 
                                className='product-input'
                                style={{width: '100%', textAlignLast: 'center', marginTop: '-1rem'}}
                                type="file"
                                id="file" name="file"
                                accept="image/*"
                                multiple
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                            />
                            <input 
                                style={{width: '100%', height: '30px', marginTop: '2rem'}}
                                type='submit'
                                disabled={disable}
                            />
                        </form>
                        <h2>{message}</h2>
                    </div>
                </div>
            </div>
        </>
    );
} 

export default ReportBug