import { useEffect } from 'react';

const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    if (url === 'https://test-gateway.tillpayments.com/js/integrated/payment.1.3.min.js') {
        script.setAttribute('data-main', 'payment-js')
        script.async = true;
    }

    if (url === 'https://gateway.tillpayments.com/js/integrated/payment.1.3.min.js') {
        script.setAttribute('data-main', 'payment-js')
        script.async = true;
    }

    document.head.appendChild(script);

    console.log(document.head);
    // return () => {
    //   document.body.removeChild(script);
    // }
  }, [url]);
};

export default useScript;