import React, { useEffect, useState } from 'react';
const Landing = () => {
    //Display Product
    const [sponsors, setSponsors] = useState([])
    const [name, setName] = useState([])

    useEffect(()=> {
        const cache = {};
        function importAll(r) {
            r.keys().forEach((key) => (cache[key] = r(key)));
        }
        importAll(require.context(__dirname +"/../../assets/sponsors", false, /\.(png|jpe?g|svg)$/));
        const images = Object.entries(cache).map(module => module[1]);
        let names = Object.entries(cache).map(module => module[0]);
        setName(names)
        setSponsors(images)
    }, [])

    return (
        <>
            <div className='message center' style={{margin: '5rem'}}>
                <div>
                    <h1>Proudly Partnered with </h1>
                </div>
                <div>
                    <img style={{filter: 'invert(1)', maxWidth: '200px', marginLeft: '1rem'}}src={require('../../assets/Pharmx.png')} alt='prod' className='product-image'/>
                </div>
            </div>
            <div className='message center'>
                <h1>Our Partners</h1>
            </div>
            <div className='img-container message center border'>
                
                    {sponsors.map((img, index)=>{
                        return(
                            <div className='' key={index}>
                                <img src={img} alt='prod' title={name[index].substring(2,name[index].length-4)} className='product-image2'/>
                            </div>
                        )
                    })}
            </div>
        </>
    );
} 

export default Landing