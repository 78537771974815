import React from 'react';
import '../login.css'

const Registered = () => {

    //Display Product
    return (
        <div className='container'>
            <div className='message center pt-44'>
                <div>
                    <h1>Thanks for registering with The Entourage Effect</h1>
                    <h3 className='text'>Your registration will be reviewed within 2 business days. You will recieve an email when your account is active.</h3>
                </div>
            </div>
        </div>
    );
} 

export default Registered