import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import NewProductCard from '../NewProductCard';
import Axios from 'axios'
import '../newProductSearchPage.css'
import { Collapse, Checkbox, Select, Form, Modal, Spin } from 'antd';
import chevronSvg from '../../assets/chevron_down.svg'
import Carousel from '../Carousel';
import { brandCheck } from '../functions/brandCheck';
const { Option } = Select;
const FormItem = Form.Item;

const { Panel } = Collapse;

const ProductSearchPage = ( {searchQuery} ) => {
    
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1130);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalSortOpen, setIsModalSortOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [products, setProducts] = useState([]);
    const [bannerData, setBannerData] = useState([]);
    const [filter, setFilter] = useState([]);
    const [searchFilter, setSearchFilter] = useState([]);
    const [selection, setSelection] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [filterName2, setFilterName2] = useState('');
    const [filterName3, setFilterName3] = useState('');
    const formOptions = [
        { label: 'Capsule', value: 'Capsule' },
        { label: 'Device', value: 'Device' },
        { label: 'Herbs', value: 'Herbs' },
        { label: 'Inhalation', value: 'Inhalation' },
        { label: 'Oil', value: 'Oil' },
    ];

    const formOptionsCategory = [
        { label: 'Accessories', value: 'Accessories' },
        { label: 'Airis', value: 'Airis' },
        { label: 'Category 1', value: 'Category 1' },
        { label: 'Category 2', value: 'Category 2' },
        { label: 'Category 3', value: 'Category 3' },
        { label: 'Category 4', value: 'Category 4' },
        { label: 'Category 5', value: 'Category 5' },
    ];
    const search = useCallback((searchString) => {
        const searchWord = searchString ? searchString : '';
        if (
            (filterName === "Choose Brand:" || filterName === "") &&
            (filterName2 === "Choose Category:" || filterName2 === "") &&
            (filterName3 === "Choose Type:" || filterName3 === "")
        ) {
            const newFilter = products.filter((value) => {
                return value.Product_Name.toLowerCase().includes(searchWord.toLowerCase());
            });
            setFilter(newFilter);
        } else {
            const newFilter = searchFilter.filter((value) => {
                return value.Product_Name.toLowerCase().includes(searchWord.toLowerCase());
            });
            setFilter(newFilter);
        }
    }, [filterName, filterName2, filterName3, products, searchFilter]);
    
    useEffect(() => {
        search(searchQuery);
    }, [searchQuery, search]);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModalSort = () => {
        setIsModalSortOpen(true);
    };
    const handleCancelSort = () => {
        setIsModalSortOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 1130);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    

    useEffect(() => {
        setIsLoading(true)
        Axios.get(`${process.env.REACT_APP_API_URL}/api/products_v2`, {
            headers: {
                authorization: localStorage.getItem('accessToken'),
                id: localStorage.getItem('cin7Id')
            }
        }).then(async(response) =>{
            await Axios.get(`${process.env.REACT_APP_API_URL}/api/banner`, {
            }).then((response)=> {
                setBannerData(response.data);
            }).catch((err)=> {
                console.log(err);
            })

            console.log(response.data)

            let d = response.data;
            // console.log(d);
            setProducts(d.sort((a, b) => a.Wholesale - b.Wholesale));
            setProducts(d.filter(i => i.Wholesale !== 0 && !i.Product_Name.toLowerCase().includes('test')));
            setFilter(d.filter(i => i.Wholesale !== 0 && !i.Product_Name.toLowerCase().includes('test')));
            let categories = [...new Set(d.map(x => x.Category))];
            setSelection(categories.filter(i => !i.toLowerCase().includes('test')));
            setIsLoading(false);
        }).catch((err)=> {
            console.log(err);
            setIsLoading(false)
            setMessage("Not Logged in, redirecting to login page...");
            setTimeout( () => {
                window.location.replace("/login");
            }, 5000);
        });
    }, []);

    const handleSortChange = (value) => {
        sortProd(value);
    };

    const sortProd = (type) => {
        if (type === 'Price: low to high') {
            setFilter([...filter].sort((a, b) => a.Wholesale - b.Wholesale));
        } else if (type === 'Price: high to low') {
            setFilter([...filter].sort((a, b) => b.Wholesale - a.Wholesale));
        }

        setIsModalSortOpen(false);
    };

    const filterButton = useCallback(() => {
        let filt = products;

        if (filterName) {
            filt = filt.filter(i => i.Category.toLowerCase() === filterName.toLowerCase());
        }
    
        if (filterName2 && filterName2 !== 'null') {
            filt = filt.filter(i => i.Sub_Category.toLowerCase() === filterName2.toLowerCase());
        }
        
        if (filterName3 && filterName3 !== 'null') {
            filt = filt.filter(i => i.Product_Types.toLowerCase() === filterName3.toLowerCase());
        }

        setSearchFilter(filt);
        setFilter(filt);
    }, [products, filterName, filterName2, filterName3]);

    useEffect(() => {
        filterButton();
    }, [filterButton]);

    if (message) {
        return (
            <div className='pt-40'> 
                <div className='message'>
                    <h1>{message}</h1>
                </div>
            </div>
        )
    }

    return (
        <div className='pt-52 pb-80 product-list-container'>
            <Spin spinning={isLoading} fullscreen />
            <Carousel data={bannerData}/>
            <div className='container'>
                {/* <div className='product-banner flex justify-center p-4' style={{display: visible}}>
                    {bannerText}
                </div> */}

                {isMobile ? 
                    <div className='flex flex-row justify-between w-100'>
                        <button onClick={showModal} className='product-sort-by w-30 justify-start mr-4 mt-16'> 
                            <div className='flex flex-row justify-center items-center'>
                                <div className='mr-2'>
                                    Filters
                                </div>
                                <img src={chevronSvg} alt="" />
                            </div>
                        </button>

                        <button onClick={showModalSort} className='product-sort-by w-30 mr-4 mt-16 justify-end'> 
                            <div className='flex flex-row justify-center items-center'>
                                <div className='mr-2'>
                                    Sort by
                                </div>
                                <img src={chevronSvg} alt="" />
                            </div>
                        </button>
                    </div>
                : 
                    <div className='product-sort-by mt-16 justify-end'>
                        <FormItem label='Sort By'
                            >
                            <Select defaultValue='Price: low to high' className='custom-select'
                                onChange={handleSortChange}
                            >
                                <Option value='Price: low to high'>Price: low to high</Option>
                                <Option value='Price: high to low'>Price: high to low</Option>
                            </Select>
                        </FormItem>
                    </div>
                }
                
                    <div className={`product-list-content flex flex-row justify-between mt-2 ${isMobile ? 'mb-40' : ''}`}>
                        {isMobile ? '' 
                            : 
                            <div className='filter-product-desktop'>
                                <div className='filter-product-title'>
                                    PRODUCT FILTERS
                                </div>
                                <Collapse className='mt-8' bordered={false}>
                                    <Panel header="Brand" key="1">
                                        {selection.map((prod, index) => (
                                            <Checkbox 
                                                key={index} 
                                                checked={prod === filterName}
                                                onChange={(e) => {
                                                    setFilterName(e.target.checked ? prod : null)
                                                }}
                                            >
                                                {prod}
                                            </Checkbox>
                                        ))}
                                    </Panel>

                                    <Panel header="Form" key="2">
                                        {formOptions.map((item) => (
                                            <Checkbox 
                                                key={item.value} 
                                                checked={item.value === filterName3}
                                                onChange={(e) => {setFilterName3(e.target.checked ? item.value : 'null')}}
                                            >
                                                {item.label}
                                            </Checkbox>
                                        ))}
                                    </Panel>

                                    <Panel header="Category" key="3">
                                        {formOptionsCategory.map((item) => (
                                            <Checkbox 
                                                key={item.value} 
                                                checked={item.value === filterName2}
                                                onChange={(e) => {setFilterName2(e.target.checked ? item.value : 'null')}}
                                            >
                                                {item.label}
                                            </Checkbox>
                                        ))}
                                    </Panel>
                                </Collapse>
                            </div>
                        }
                        <div className={`${isMobile ? 'product-list-mobile' : 'product-list-desktop'} flex flex-row`}>
                            { filter.map ((prod, index) => {
                                return (
                                    <div key={index} className='product-card-item'>
                                        <Link to={"/products/" + prod.Style_Code}>
                                            <NewProductCard 
                                                img={'http://go.cin7.com/webfiles/TheEntourageEffeAU/webpages/images/998159/'+prod.Image_1} 
                                                price={Number(prod.Wholesale).toFixed(2)} 
                                                rrp={Number(prod.Retail).toFixed(2)} 
                                                name={prod.Stock_Avail > 0 ? prod.Product_Name : "**Temporarily out of stock** " + prod.Product_Name}
                                                brand={prod.Product_Sub_Type ? brandCheck(prod.Product_Sub_Type) : "-"}
                                                form={prod.Product_Types ? prod.Product_Types : null}
                                            />
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
            </div>
                <Modal title="PRODUCT FILTERS" open={isModalOpen}
                    onCancel={handleCancel}
                    footer={[]}
                >
                    <div className='filter-product-mobile'>
                        <Collapse className='mt-8' bordered={false}>
                            <Panel header="Brand" key="1">
                                {selection.map((prod, index) => (
                                    <Checkbox 
                                        key={index} 
                                        checked={prod === filterName}
                                        onChange={(e) => {
                                            setFilterName(e.target.checked ? prod : null)
                                        }}
                                    >
                                        {prod}
                                    </Checkbox>
                                ))}
                            </Panel>

                            <Panel header="Form" key="2">
                                {formOptions.map((item) => (
                                    <Checkbox 
                                        key={item.value} 
                                        checked={item.value === filterName3}
                                        onChange={(e) => {setFilterName3(e.target.checked ? item.value : null)}}
                                    >
                                        {item.label}
                                    </Checkbox>
                                ))}
                            </Panel>

                            <Panel header="Category" key="3">
                                {formOptionsCategory.map((item) => (
                                    <Checkbox 
                                        key={item.value} 
                                        checked={item.value === filterName2}
                                        onChange={(e) => {setFilterName2(e.target.checked ? item.value : null)}}
                                    >
                                        {item.label}
                                    </Checkbox>
                                ))}
                            </Panel>
                        </Collapse>
                    </div>
                </Modal>

                <Modal title="SORT BY" open={isModalSortOpen}
                    onCancel={handleCancelSort}
                    footer={[]}
                >
                    <div className='flex flex-col mt-8'>
                        <button onClick={() => {sortProd('Recommended')}} className='sort-btn-title-mobile w-100 flex justify-start mb-6'>
                            Recommended
                        </button>
                        <button onClick={() => {sortProd('New arrivals')}} className='sort-btn-title-mobile w-100 flex justify-start mb-6'>
                            New arrivals
                        </button>
                        <button onClick={() => {sortProd('Best sellers')}} className='sort-btn-title-mobile w-100 flex justify-start mb-6'>
                            Best sellers
                        </button>
                        <button onClick={() => {sortProd('Price: low to high')}} className='sort-btn-title-mobile w-100 flex justify-start mb-6'>
                            Price: low to high
                        </button>
                        <button onClick={() => {sortProd('Price: high to low')}} className='sort-btn-title-mobile w-100 flex justify-start'>
                            Price: high to low
                        </button>
                    </div>
                </Modal>
        </div>
    );
} 

export default ProductSearchPage